import React, { useEffect, useRef, useState } from "react";
import { Button, Card, CardContent, MenuItem } from "@mui/material";
import { Grid } from "@mui/material";
import { CircularProgress } from "@mui/material";
import { TextField } from "formik-material-ui";
import { Field, Form, Formik } from "formik";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { addressSchema } from "./ValidationSchema";
import { addressData } from "./EmptyData";
import ENV from "../../environment";

export default function Address({ setStep, token }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [country, setCountry] = useState([]);
  const [regions, setRegions] = useState([]);
  const [cities, setCities] = useState([]);

  useEffect(() => {
    const getCountry = async () => {
      try {
        const res = await axios(`${ENV.COMMON_API}/tts/api/v01/countries`, {
          headers: {
            "client-id": ENV.COMMON_ID,
            secret: ENV.COMMON_SECRET,
          },
        });
        setCountry(res.data.data);
      } catch (err) {
        toast.error(err?.response?.data?.errors[0]?.message || t("An error occurred"));
      }
    };

    const getRegions = async () => {
      try {
        const res = await axios(`${ENV.COMMON_API}/tts/api/v01/region/14ed6cfc-d787-45ba-b44d-84ac06e37935`, {
          headers: {
            "client-id": ENV.COMMON_ID,
            secret: ENV.COMMON_SECRET,
          },
        });
        setRegions(res.data.data);
      } catch (err) {
        toast.error(err?.response?.data?.errors[0]?.message || t("An error occurred"));
      }
    };

    (async () => {
      await getCountry();
      await getRegions();
    })();
  }, []);

  const submit = async (data) => {
    setLoading(true);
    if (!data.address2) data.address2 = null;
    if (!data.zip_code) data.zip_code = null;
    try {
      await axios.post(`${ENV.API_URL}/hdp-gateway/fth/address`,{address1:data.address1,address2:data.address2,city:data.city,country:data.country,region:data.region,zip_code:data.zip_code}, {
        headers: { Authorization: token },
      });
      setLoading(false);
      setStep("generate_id");
    } catch (err) {
      setLoading(false);
      toast.error(err?.response?.data?.errors[0]?.message || t("An error occurred"));
    }
  };

  const onRegionChange = async (regionName) => {
    const region = regions.find((element) => element.name === regionName);
    if (region?.id) {
      try {
        const res = await axios(`${ENV.COMMON_API}/tts/api/v01/oneregioncities/${region.id}`, {
          headers: {
            "client-id": ENV.COMMON_ID,
            secret: ENV.COMMON_SECRET,
          },
        });
        setCities(res.data.data);
      } catch (err) {
        toast.error(err?.response?.data?.errors[0]?.message || t("An error occurred"));
      }
    }
  };

  return (
    <div className="d-flex justify-content-center flex-column container py-3">
      <div className="py-2">
        <div className="card">
          <div className="card-header">
            <h5 className="text-center">Address</h5>
            {loading && <div className="overlay" />}
          </div>
          <Card>
            <CardContent style={{ padding: "24px" }}>
              <Formik
                initialValues={addressData}
                validationSchema={addressSchema}
                onSubmit={async (values) => {
                  await submit(values);
                  return new Promise((res) => setTimeout(res, 100));
                }}
              >
                {({ values, errors, touched, isSubmitting }) => (
                  <Form autoComplete="off">
                    <Grid container direction="column" spacing={2}>
                      <Grid item container spacing={2} xs={12}>
                        <Grid item xs={12} sm={4}>
                          <Field
                            size="small"
                            fullWidth
                            select
                            component={TextField}
                            disableCloseOnSelect
                            onClick={() => {
                              values.region = undefined;
                              values.city = undefined;
                            }}
                            name="country"
                            label="Select Country"
                          >
                            {country.length > 0 && country.map((el) => (
                              <MenuItem key={el.id} value={el.name}>
                                {el.native_name}
                              </MenuItem>
                            ))}
                          </Field>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          {values.country === "Uzbekistan" ? (
                            <Field
                              size="small"
                              fullWidth
                              select
                              id="regions"
                              onClick={() => {
                                values.city = null;
                              }}
                              onChange={(e) => {
                                values.city = null;
                                onRegionChange(e.target.value);
                                values.region = e.target.value;
                              }}
                              component={TextField}
                              name="region"
                              label="Select Region"
                            >
                              {regions.length > 0 && regions.map((el) => (
                                <MenuItem key={el.id} value={el.name}>
                                  {el.native_name}
                                </MenuItem>
                              ))}
                            </Field>
                          ) : (
                            <Field
                              size="small"
                              fullWidth
                              name="region"
                              component={TextField}
                              label="Region"
                            />
                          )}
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          {values.country === "Uzbekistan" ? (
                            <Field
                              size="small"
                              fullWidth
                              select
                              component={TextField}
                              name="city"
                              label="City / Village"
                            >
                              {cities.length > 0 && cities.map((el) => (
                                <MenuItem key={el.id} value={el.name}>
                                  {el.name}
                                </MenuItem>
                              ))}
                            </Field>
                          ) : (
                            <Field
                              size="small"
                              fullWidth
                              name="city"
                              component={TextField}
                              label="City / Village"
                            />
                          )}
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <Field
                            size="small"
                            fullWidth
                            name="address1"
                            component={TextField}
                            label="Address 1"
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <Field
                            size="small"
                            fullWidth
                            name="address2"
                            component={TextField}
                            label="Address 2"
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <Field
                            size="small"
                            fullWidth
                            name="zip_code"
                            component={TextField}
                            label="Zipcode"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Button
                            size="small"
                            type="submit"
                            variant="contained"
                            className="float-end"
                            disabled={loading}
                            startIcon={loading && <CircularProgress size="0.9rem" />}
                          >
                            Next
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
}
