export const personalData = {
  first_name: "",
  last_name: "",
  middle_name: "",
  password: "",
};
export const addressData = {
  country: "",
  region: "",
  city: "",
  address1: "",
  address2: "",
  zip_code: "",
  region_id:""
};
