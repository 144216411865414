import React, { useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import { Grid, MenuItem } from "@mui/material";
import axios from "axios";
import { useTranslation } from "react-i18next";
import ENV from "../../../environment";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function TransitionsModal({ getData }) {
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);
  const [categories, setCategories] = useState([]);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  
  
  const validate = Yup.object({
    room_category_id: Yup.number().required(t("Field is required")),
    from_date: Yup.date().required("Field is required"),
    to_date: Yup.date().required("Field is required"),
    current_price: Yup.number().required(t("Field is required")),
    discount: Yup.number().required(t("Field is required")).min(1, t("Value must not be less than 0")).max(100, "The value must not be greater than 100"),
    final_price: Yup.number().required(t("Field is required")),
  });
  const emptyData = {
    room_category_id: "",
    from_date: "",
    to_date: "",
    discount: "",
    current_price: "",
    final_price: "",
  };

  const onSubmit = async (values) => {
    const data = {
      room_category_id: values.room_category_id,
      from_date: values.from_date,
      to_date: values.to_date,
      discount: values.discount,
    };

    setLoading(true);
    await axios
      .post(`${ENV.API_URL}/hdp-gateway/discount`, data, {
        headers: {
          Authorization: sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        setLoading(false);
        toast.success(t("Saved successfully"));
        handleClose();
        getData();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(
          err?.response?.data?.errors[0]?.message || t("An error occurred")
        );
      });
  };

  const onChange = async (e, values) => {
    values.room_category_id = e.target.value;
    if (e) {
      await axios
        .get(`${ENV.API_URL}/hdp-gateway/room/category/${e.target.value}`, {
          headers: { Authorization: sessionStorage.getItem("token") },
        })
        .then((res) => {
          values.current_price = res.data.data.price;
          document.activeElement.blur();
        })
        .catch((err) => {
          toast.error(
            err?.response?.data?.errors[0]?.message || t("An error occurred")
          );
        });
    }
  };

  const onDiscount = async (e, values) => {
    values.discount = e.target.value
    let current_price = values.current_price
    let discount = (values.discount / 100) * current_price
    let discount_price = current_price - discount
    values.final_price = discount_price
  };

  const getCategories = async () => {
    await axios
      .get(`${ENV.API_URL}/hdp-gateway/room/category`, {
        headers: { Authorization: sessionStorage.getItem("token") },
      })
      .then((res) => {
        setCategories(res.data.data);
      })
      .catch((err) => {
        toast.error(
          err?.response?.data?.errors[0]?.message || t("An error occurred")
        );
      });
  };
setTimeout(()=>{
  const today = new Date()?.toISOString()?.split("T")[0];
  document?.getElementById("date-input1")?.setAttribute("min", today);
  document?.getElementById("date-input2")?.setAttribute("min", today);
},1000)
  useEffect(() => {
    getCategories();
  }, []);

  return (
    <div className="modal_res">
      <Button onClick={handleOpen} variant="contained">
        {t("Add discount")}
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style} className="modal_res">
            <Typography
              id="transition-modal-title"
              variant="h6"
              component="h2"
              className="marginBottom"
            >
              {t("Enter discount info")}
            </Typography>
            <Formik
              initialValues={emptyData}
              validationSchema={validate}
              onSubmit={async (values) => {
                onSubmit(values);
                return new Promise((res) => setTimeout(res, 500));
              }}
            >
              {({ values, errors }) => (
                <Form autoComplete="off" className="mt-4">
                  <Grid container spacing={4}>
                    <Grid item xs={12}>
                      <Field
                        fullWidth
                        select
                        name="room_category_id"
                        size="small"
                        component={TextField}
                        label={t("Select Category")}
                        onChange={(e) => onChange(e, values)}
                      >
                        {categories.map((item, index) => {
                          return (
                            <MenuItem value={item.id} key={index}>
                              {item.name}
                            </MenuItem>
                          );
                        })}
                      </Field>
                    </Grid>
                    <Grid item xs={12}>
                      <label>{t("From Date")}</label>
                      <Field
                        id="date-input1"
                        // onInput={(e) => onStartDate(e, values)}
                        fullWidth
                        size="small"
                        name="from_date"
                        component={TextField}
                        type={"date"}
                        // disabled={data.id ? true : false}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <label>{t("To Date")}</label>
                      <Field
                        id="date-input2"
                        // onInput={(e) => onStartDate(e, values)}
                        fullWidth
                        size="small"
                        name="to_date"
                        component={TextField}
                        type={"date"}
                        // disabled={data.id ? true : false}
                        min=""
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        fullWidth
                        name="current_price"
                        size="small"
                        component={TextField}
                        label={t("Current price")}
                        disabled={true}
                        type="number"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        fullWidth
                        name="discount"
                        size="small"
                        component={TextField}
                        label={t("Discount (in percent)")}
                        type="number"
                        min="1" max="10"
                        onInput={(e) => onDiscount(e, values)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        fullWidth
                        name="final_price"
                        size="small"
                        component={TextField}
                        label={t("Discount price")}
                        disabled
                        type="number"
                      />
                    </Grid>
                  </Grid>
                  <div className="right_left">
                    <Button
                      onClick={handleClose}
                      variant="contained"
                      sx={{ mt: 3, mb: 2 }}
                      color="error"
                    >
                      {t("Close")}
                    </Button>
                    <>
                      {loading ? (
                        <LoadingButton
                          loading
                          variant="contained"
                          sx={{ mt: 3, mb: 2 }}
                          color="success"
                        >
                          {t("Submit")}
                        </LoadingButton>
                      ) : (
                        <Button
                          type="submit"
                          variant="contained"
                          sx={{ mt: 3, mb: 2 }}
                          color="success"
                        >
                          {t("Submit")}
                        </Button>
                      )}
                    </>
                  </div>
                  {loading && <div className="overlay" />}
                </Form>
              )}
            </Formik>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

export default React.memo(TransitionsModal);
