import { Calendar, momentLocalizer } from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { useTranslation } from "react-i18next";
import MenuItem from '@mui/material/MenuItem';
import ModalCalendar from "./ModalCalendar";
import Button from "@mui/material/Button";
import Select from '@mui/material/Select';
import { toast } from "react-toastify";
import Box from '@mui/material/Box';
import { useEffect } from "react";
import { useState } from "react";
import moment from "moment";
import axios from "axios";
import ENV from "../../../environment";


const localizer = momentLocalizer(moment);
const DnDCalendar = withDragAndDrop(Calendar);


// react BigCalendar component
const BigCalendar = ({ events, setEvents }) => {
  const {t} = useTranslation()
  const [type, setType] = useState('');
  const [types, setTypes] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [number, setNumber] = useState('');
  const [categories, setCategories] = useState([]);

  const [modalStatus, setModalStatus] = useState(false);
  const [editStatus, setEditStatus] = useState(false);
  const [eventInput, setEventInput] = useState("");
  const [startDate, setStartDate] = useState("");
  const [loading, setLoading] = useState(false);
  const [endDate, setEndDate] = useState("");
  const [eventId, setEventId] = useState("");

  const handleClose = () => {
    setModalStatus(false);
    setEditStatus(false);
    setEventInput("");
  };

  // select and get for only calendar not modal
  const getBooking = async (value) => {
    await axios(`${ENV.API_URL}/hdp-gateway/booking/${value}`,
      { headers: { Authorization: sessionStorage.getItem('token') }}
    ).then(res => {
      res.data.data?.forEach(el => {
          el.start = new Date(el.start_date)
          el.end = new Date(el.end_date)
          el.title = <p id="c_title" className={`${(el.type === 1 && 'green') || (el.type === 2 && 'purple') || (el.type === 3 && 'blue') || (el.type === 4 && 'yellow') || (el.type === 5 && 'orange')}`}>{el.room_number}-xona 
              <span id="f_name" className={`${(el.type === 1 && 'green') || (el.type === 2 && 'purple') || (el.type === 3 && 'blue') || (el.type === 4 && 'yellow') || (el.type === 5 && 'orange')}`}>{el.first_name?.slice(0, 1)+"."+el.last_name}</span>
            </p>
      }) 
      setEvents(res.data.data);
    }).catch(err => toast.error(err?.response?.data?.errors[0]?.message || t("An error occurred")))
  }

  const handleSave = async (e) => {
    setLoading(true)
    if((typeof e.start_date) === 'object') 
      e.start_date = e.start_date?.toISOString();
    if((typeof e.end_date) === 'object') 
      e.end_date = e.end_date?.toISOString();

    const body = { 
      first_name: e.first_name,
      last_name: e.last_name,
      start_date: e.start_date?.slice(0, 10),
      end_date: e.end_date?.slice(0, 10),
      room_category: e.room_category,
      room_id: e.room_id,
      debit: e.debit,
      credit: e.credit,
      type: e.type,
      comments: e.comments,
    }

    if(e.id) body.id = e.id;
    if(!e.id) body.debit = e.amount;
    if(!e.credit) body.credit = undefined;
    if(!e.comments) body.comments = undefined;

    await axios[e.id ? `put` : `post`](`${ENV.API_URL}/hdp-gateway/booking`, body,
      { headers: { Authorization: sessionStorage.getItem("token") } })
      .then((res) => { 
        setModalStatus(false); 
        getBooking(''); setLoading(false); 
        toast.success("Successfully") 
      })
      .catch((err) => { toast.error(err?.response?.data?.errors[0]?.message || t("An error occurred")); setLoading(false) });
  };

  //slot select handler
  const handleSlotSelectEvent = (slotInfo) => {
    setStartDate(new Date(new Date(slotInfo.start).setHours(slotInfo.start.getHours() + 5)));
    setEndDate(new Date(`${slotInfo.end}`));
    setModalStatus(true);
    setEventInput("");
  };

    //move event handler
  const moveEventHandler = ({ event, start, end }) => {
    if (event.first_name) {
      const body = { 
        id: event.id,
        first_name: event.first_name,
        last_name: event.last_name,
        start_date: new Date(`${start}`)?.toISOString().slice(0,10), 
        end_date: new Date(`${end}`)?.toISOString().slice(0,10), 
        room_category: event.room_category,
        room_id: event.room_id,
        type: event.type,
        comments: event.comments ? event.comments : undefined,
      }
      axios.put(`${ENV.API_URL}/hdp-gateway/booking`, body,
        { headers: { Authorization: sessionStorage.getItem("token") } })
        .then((res) => { setModalStatus(false); getBooking(''); toast.success("Successfully")})
        .catch((err) => toast.error(err?.response?.data?.errors[0]?.message || t("An error occurred")));
    }
  };

  const onCalculateTotal = (event) => {
    rooms.map((el, i) => {
     if(el.id === event.room_id){
      const db = event.amount;
        const start = new Date(event.start_date);
        const end = new Date(event.end_date);
        const difference_time  = end.getTime() - start.getTime();
        let days = Math.round(difference_time / (1000 * 3600 * 24));
        if((days) <= 1) event.amount = el.price;
        else event.amount = days * el.price;

        event.debit = event.amount - db;
      }
    });
  }

  //resize event handler
  const resizeEventHandler = ({ event, start, end }) => {
    if (event.first_name) {
      setEditStatus(true);
      setStartDate(new Date(`${start}`));
      setEndDate(new Date(`${end}`));
      event = {...event, start_date: new Date(`${start}`), end_date: new Date(`${end}`)}
      onCalculateTotal(event)
      setEventInput(event);
      setEventId(event.id);
      setModalStatus(true);
    }
  };

  //on select event handler
  const hanldeOnSelectEvent = (e) => {
    if (e.first_name) {
      setStartDate(new Date(`${e.start_date}`));
      setEndDate(new Date(`${e.end_date}`));
      setEditStatus(true);
      setEventInput(e);
      setEventId(e.id);
      setModalStatus(true);
    }
  };

  const handleDelete = (e) => {
    axios.delete(`${ENV.API_URL}/hdp-gateway/booking`, 
    { data: { id: `${eventId}` }, headers: { Authorization: sessionStorage.getItem("token") }})
    .then((res) => { setModalStatus(false); setEventInput(""); getBooking('') })
    .catch((err) => toast.error(err?.response?.data?.errors[0]?.message || t("An error occurred")));
  };

  const handleChanges = async (e) => {
    setType(''); setNumber(e.target.value);
    await getBooking(`calendar/${e.target.value}`)
  };

  const handleType = async (e) => {
    setNumber(''); setType(e.target.value);
    await getBooking(`type/${e.target.value}`)
  };

  const handleClear = async (e) => {
    setNumber(''); setType('');
    await getBooking('')
  };

  const getRooms = async () => {
    await axios(`${ENV.API_URL}/hdp-gateway/room`,
      { headers: { Authorization: sessionStorage.getItem('token') }}
    ).then(res => setRooms(res.data.data))
    .catch(err => toast.error(err?.response?.data?.errors[0]?.message || t("An error occurred")))

    await axios(`${ENV.API_URL}/hdp-gateway/booking/types`,
      { headers: { Authorization: sessionStorage.getItem('token') }}
    ).then(res => setTypes(res.data.data))
    .catch(err => toast.error(err?.response?.data?.errors[0]?.message || t("An error occurred")))
  }

  const getCategories = async () => {
    await axios.get(`${ENV.API_URL}/hdp-gateway/room/category`, {
        headers: { Authorization: sessionStorage.getItem("token") } })
      .then((res) => setCategories(res.data.data))
      .catch((err) => toast.error(err?.response?.data?.errors[0]?.message || t("An error occurred")));
  };
  
  useEffect(() => {
    getRooms()
    getCategories()
  }, [])
  
  return (
    <div className="my-calendar">
       <Box sx={{ minWidth: 120, marginBottom: 2 }} className="row g-5 mb-4">
        <div className="col-md-5">
          <FormControl fullWidth size="small">
            <InputLabel id="demo-simple-select-label">{t("Room number")}</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              value={number} label="Room number"
              id="demo-simple-select"
              onChange={handleChanges}
            >
              {rooms.map((item, index) => ( <MenuItem value={item.id} key={item.id}>{item.number} - xona</MenuItem> ))}
            </Select>
          </FormControl>
        </div>
        <div className="col-md-5">
          <FormControl fullWidth size="small">
            <InputLabel id="demo-simple-select-label-type">{t("Booking type")}</InputLabel>
            <Select
              labelId="demo-simple-select-label-type"
              value={type} label="Booking type"
              id="demo-simple-select-type"
              onChange={handleType}
            >
              {types.map((item, index) => ( <MenuItem value={item.id} key={item.id}>{item.name}</MenuItem> ))}
            </Select>
          </FormControl>
        </div>
        <div className="col-md-2 d-flex align-items-center justify-content-end">
            <Button variant="contained" color="success" onClick={handleClear}>{t("All")}</Button>
        </div>
      </Box>
      <DnDCalendar
        localizer={localizer}
        events={events}
        tooltipAccessor={"tooltip"}
        startAccessor="start"
        endAccessor="end"
        selectable
        onSelectSlot={handleSlotSelectEvent}
        onSelectEvent={hanldeOnSelectEvent}
        onEventDrop={moveEventHandler}
        resizable
        onEventResize={resizeEventHandler}
        onSelecting={slot => false}
        longPressThreshold={10}
        eventPropGetter={() => ({style: { borderLeft: "3px solid red", borderRadius: "0" }})}
      />
      <ModalCalendar
        modalStatus={modalStatus}
        handleClose={handleClose}
        handleSave={handleSave}
        startDate={startDate}
        endDate={endDate}
        eventInput={eventInput}
        editStatus={editStatus}
        handleDelete={handleDelete}
        categories={categories}
        types={types} 
        loading={loading}
      />
    </div>
  );
};

export default BigCalendar;