import React, { useState, useEffect } from "react";
import styles from "./pult-style.module.css";
import { useTranslation } from "react-i18next";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import cn from "classnames";
import { ConfigProvider, DatePicker, Space } from "antd";
import locale from "antd/locale/en_US";
import dayjs from "dayjs";
import "dayjs/locale/uz-latn";

export default function ShaxmatkaPult({ today, next, prev }) {
    const { t } = useTranslation();
    const [click, setClick] = useState("");

    const nextDay = () => {
        setClick("next");
        next();

        setTimeout(() => {
            setClick("");
        }, 100);
    };

    const prevDay = () => {
        setClick("prev");
        prev();

        setTimeout(() => {
            setClick("");
        }, 100);
    };

    // const changeDay = (value) => {
    //     if (value?.$d) {
    //         setToday(new Date(value.$d));
    //         document.getElementById("pult-date").blur();
    //     }
    // };

    const keyUp = (e) => {
        // if (e.keyCode === 39) nextDay();
        // if (e.keyCode === 37) prevDay();
    };

    useEffect(() => {
        document.addEventListener("keyup", keyUp);
        return () => {
            document.removeEventListener("keyup", keyUp);
        };
    }, [today]);

    return (
        <div className={styles.pult}>
            <div className={cn(styles.arrow, styles.arrowPrev, click === "prev" && styles.arrowClick)} onClick={prevDay}>
                <SkipPreviousIcon />
            </div>

            {/* <ConfigProvider locale={locale}>
                <DatePicker id="pult-date" value={dayjs(today.toISOString().slice(0, 10), "YYYY-MM-DD")} onChange={changeDay} />
            </ConfigProvider> */}

            <div className={cn(styles.arrow, styles.arrowNext, click === "next" && styles.arrowClick)} onClick={nextDay}>
                <SkipNextIcon />
            </div>
        </div>
    );
}
