import React from "react";
import "./style.css";
import List from "./List";

const Policy = () => {
    return (
        <div className="policy">
            <List />
        </div>
    );
};

export default Policy;
