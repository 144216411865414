import {
  PieChartOutlined,
} from "@ant-design/icons";
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import ImageIcon from '@mui/icons-material/Image';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import BedIcon from "@mui/icons-material/Bed";
import GroupIcon from "@mui/icons-material/Group";
import ContactsIcon from '@mui/icons-material/Contacts';
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import { MdCleaningServices } from "react-icons/md";
import CategoryIcon from "@mui/icons-material/Category";
import AssignmentIcon from "@mui/icons-material/Assignment";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import PeopleIcon from "@mui/icons-material/People";
import ReportIcon from '@mui/icons-material/Report';
import InfoIcon from '@mui/icons-material/Info';
import BedroomParentIcon from '@mui/icons-material/BedroomParent';
import DiscountIcon from '@mui/icons-material/Discount';
import React, { useContext, useEffect, useState } from "react";
import { Layout, Menu } from "antd";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import hotel from "../../assets/logo.png";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { createGet } from "../Context/GetRolesContext";
import ENV from "../../environment";
const { Sider } = Layout;

function Side(props) {
  const [get, setGet] = useContext(createGet);
  const [items, setItems] = useState([]);
  const [ispartner, setIspartner] = useState(false);
  const [logo, setLogo] = useState(false);
  const { collapsed, selectedKeys, handleOpenMainModal } = props;
  const { t } = useTranslation();

  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }
  const navigate = useNavigate();

  const Manageritems = [
    getItem(t("Home"), "/", <PieChartOutlined />),
    getItem(t("Master book"), "/master-book", <StickyNote2Icon />),
    getItem(t("Reports"), "sub0", <ReportIcon />, [
      getItem(t("Monthly report"), "/monthly-report", <ReportIcon />),
      getItem(t("Daily report"), "/daily-report", <ReportIcon />),
      getItem(t("Booking report"), "/booking-report", <ReportIcon />),
    ]),
    getItem(t("Admin"), "sub2", <AdminPanelSettingsIcon />, [
      getItem(t("Employees"), "/admin-users", <GroupIcon />),
      getItem(t("Contacts"), "/contacts", <ContactsIcon />),
      getItem(t("Categories"), "/categories", <CategoryIcon />),
      getItem(t("Discount"), "/discount", <DiscountIcon />),
      getItem(t("Rooms"), "/admin-rooms", <BedIcon />),
      getItem(t("Role assignment"), "/role-assignment", <AssignmentIcon />),
      getItem(t("Hotel"), "sub1", <BedroomParentIcon />, [
        getItem(t("Logo"), "/hotel-logo", <ImageIcon />),
        getItem(t("Hotel images"), "/hotel-images", <FactCheckIcon />),
        getItem(t("Amenities"), "/hotel-amenities", <CategoryIcon />),
        getItem(t("Info"), "/hotel-info", <InfoIcon />),
        getItem(t("Policy"), "/hotel-policy", <InfoIcon />),
      ]),
    ]),
    getItem(t("Housekeeping"), "sub3", <MdCleaningServices />, [
      getItem(t("Room assignment"), "/housekeeping-assignment", <AssignmentTurnedInIcon />),
      getItem(t("Housekeeper pdf"), "/housekeeping-pdf", <MdCleaningServices />),
      getItem(t("Rooms"), "/housekeepers-rooms", <PeopleIcon />),
    ]),
  ];
  const Partneritems = [
    getItem(t("Home"), "/", <PieChartOutlined />),
    getItem(t("Master book"), "/master-book", <StickyNote2Icon />),
    getItem(t("Reports"), "sub0", <ReportIcon />, [
      getItem(t("Monthly report"), "/monthly-report", <ReportIcon />),
      getItem(t("Daily report"), "/daily-report", <ReportIcon />),
      getItem(t("Booking report"), "/booking-report", <ReportIcon />),
    ]),
    getItem(t("Admin"), "sub2", <AdminPanelSettingsIcon />, [
      getItem(t("Employees"), "/admin-users", <GroupIcon />),
      getItem(t("Contacts"), "/contacts", <ContactsIcon />),
      getItem(t("Categories"), "/categories", <CategoryIcon />),
      getItem(t("Discount"), "/discount", <DiscountIcon />),
      getItem(t("Rooms"), "/admin-rooms", <BedIcon />),
      getItem(t("Role assignment"), "/role-assignment", <AssignmentIcon />),
      getItem(t("Hotel"), "sub1", <BedroomParentIcon />, [
        getItem(t("Logo"), "/hotel-logo", <ImageIcon />),
        getItem(t("Hotel images"), "/hotel-images", <FactCheckIcon />),
        getItem(t("Amenities"), "/hotel-amenities", <CategoryIcon />),
        getItem(t("Info"), "/hotel-info", <InfoIcon />),
        getItem(t("Policy"), "/hotel-policy", <InfoIcon />),
      ]),
    ]),
    getItem(t("Housekeeping"), "sub3", <MdCleaningServices />, [
      getItem(t("Room assignment"), "notallowed", <AssignmentTurnedInIcon />),
      getItem(t("Housekeeper pdf"), "notallowed", <MdCleaningServices />),
      getItem(t("Rooms"), "notallowed", <PeopleIcon />),
    ]),
  ];
  const Supervisoritems = [
    getItem(t("Home"), "/", <PieChartOutlined />),
    getItem(t("Master book"), "/master-book", <StickyNote2Icon />),
    getItem(t("Reports"), "sub0", <ReportIcon />, [
      getItem(t("Monthly report"), "/monthly-report", <ReportIcon />),
      getItem(t("Daily report"), "/daily-report", <ReportIcon />),
      getItem(t("Booking report"), "/booking-report", <ReportIcon />),
    ]),
    getItem(t("Housekeeping"), "sub2", <MdCleaningServices />, [
      getItem(t("Room assignment"), "/housekeeping-assignment", <AssignmentTurnedInIcon />),
      getItem(t("Housekeeper pdf"), "/housekeeping-pdf", <MdCleaningServices />),
      getItem(t("Rooms"), "/housekeepers-rooms", <PeopleIcon />),
    ]),
  ];

  const Housekeeperitems = [
    getItem(t("Home"), "/", <PieChartOutlined />),
    getItem(t("Master book"), "/master-book", <StickyNote2Icon />),
    getItem(t("Reports"), "sub0", <ReportIcon />, [
      getItem(t("Monthly report"), "/monthly-report", <ReportIcon />),
      getItem(t("Daily report"), "/daily-report", <ReportIcon />),
      getItem(t("Booking report"), "/booking-report", <ReportIcon />),
    ]),
    getItem(t("Housekeeping"), "sub2", <MdCleaningServices />, [
      getItem(t("Room assignment"), "/housekeeping-assignment", <AssignmentTurnedInIcon />),
      getItem(t("Housekeeper pdf"), "/housekeeping-pdf", <MdCleaningServices />),
      getItem(t("Rooms"), "/housekeepers-rooms", <PeopleIcon />),
    ]),
  ];

  const Frontdeskitems = [
    getItem(t("Home"), "/", <PieChartOutlined />),
    getItem(t("Master book"), "/master-book", <StickyNote2Icon />),
    getItem(t("Reports"), "sub0", <ReportIcon />, [
      getItem(t("Monthly report"), "/monthly-report", <ReportIcon />),
      getItem(t("Daily report"), "/daily-report", <ReportIcon />),
      getItem(t("Booking report"), "/booking-report", <ReportIcon />),
    ]),
  ];

  const getLogo = async () => {
    if (!props?.uuid) return;
    await axios
      .get(`${ENV.API_URL}/hdp-gateway/hotel/logo/${props.uuid}`, {
        responseType: "blob",
        headers: {
          Authorization: sessionStorage.getItem("token"),
        },
      })
      .then((response) => {

        const reader = new FileReader();
        reader.onload = (event) => {
          if (
            response?.data?.type === "application/pdf" ||
            /image/.test(response.data.type)
          ) {
            window.document.getElementById(
              "test"
            ).src = `${event?.target?.result}`;
          } else {
            console.log("else");
          }
        };
        reader.readAsDataURL(response?.data);
        setLogo(true);
      })
      .catch((err) => {
        setLogo(false);
      });
  };

  const getRoles = async () => {
    await axios
      .get(`${ENV.API_URL}/hdp-gateway/user/me`, {
        headers: {
          authorization: sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        const arr = res.data.data.roles
        const isManager = arr.some(role => role.role === "manager");
        const isAdmin = arr.some(role => role.role === "admin");
        const isSupervisor = arr.some(role => role.role === "supervisor");
        const isFront = arr.some(role => role.role === "front desk");
        const isHousekeeper = arr.some(role => role.role === "housekeeper");
        const isPartner = sessionStorage.getItem("partner") === "partner";
        if (isManager || isAdmin) setItems(Manageritems);
        else if (isSupervisor) setItems(Supervisoritems);
        else if (isFront) setItems(Frontdeskitems);
        else if (isHousekeeper) setItems(Housekeeperitems);
        else if (isPartner) {
          setItems(Partneritems)
          setIspartner(true)
        };
      })
      .catch((err) => {
        toast.error(err?.response?.data?.errors[0]?.message || t("An error occurred"))
      });
  };

  useEffect(() => {
    getRoles();
    getLogo();
  }, [props?.uuid]);

  useEffect(() => {
    getRoles();
  }, [!get]);

  return (
    <Sider
      trigger={null}
      collapsible
      collapsed={collapsed}
      style={{ overflow: "auto", minHeight: "100vh" }}
    >
      <div className="logo_wrapper">
        {logo ? (
          <img id="test" title="test" alt="Logo" />
        ) : (
          <img id="dontLogo" src={hotel} alt="don't have logo" />
        )}
      </div>
      <Menu
        onClick={(item) => {
          if (ispartner && item?.key == 'notallowed') {
            handleOpenMainModal()
          }
          else {
            navigate(item.key)
          }
        }}
        defaultOpenKeys={["/"]}
        mode="inline"
        theme="dark"

        selectedKeys={[selectedKeys]}
        items={items}
      />
    </Sider>
  );
}

export default Side;
