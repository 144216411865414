import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {IconButton, Tooltip } from "@mui/material";
import Avatar from '@mui/joy/Avatar';
import GuestInfoModal from "./GuestInfoModal";
import ViewDoc from "./ViewDoc";

export default function BasicMenu({ data }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Tooltip title="Actions">
        <IconButton
          onClick={handleClick}
          size="small"
          sx={{ ml: 2 }}
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          <Avatar  variant="outlined" sx={{ width: 32, height: 32 }}></Avatar>
        </IconButton>
      </Tooltip>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem>
          <GuestInfoModal data={data} />
        </MenuItem>
        {data?.document_attachment && (
          <MenuItem>
            <ViewDoc uuid={data.document_attachment} />
          </MenuItem>
        )}
      </Menu>
    </div>
  );
}
