import { Alert } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

export default function BarChartActive({ data }) {
  const { t } = useTranslation();
  return (
    <div className="chart_bar_flex">
      <h5 className="text-primary">{t("Reservations")}</h5>
      {data.length ? (
        <ResponsiveContainer width="101%" height={350} className="margin_right">
          <BarChart data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="month" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar
              dataKey="walk-in"
              fill="#d89384"
              activeBar={<Rectangle fill="pink" stroke="blue" />}
            />
            <Bar
              dataKey="expedition"
              fill="#cabf82"
              activeBar={<Rectangle fill="yellow" stroke="purple" />}
            />
            <Bar
              dataKey="online"
              fill="#82ca8a"
              activeBar={<Rectangle fill="green" stroke="purple" />}
            />
            <Bar
              dataKey="local"
              fill="#82c6ca"
              activeBar={<Rectangle fill="grey" stroke="purple" />}
            />
            <Bar
              dataKey="corporate"
              fill="#8293ca"
              activeBar={<Rectangle fill="blue" stroke="purple" />}
            />
          </BarChart>
        </ResponsiveContainer>
      ) : (
        <Alert variant="outlined" severity="info">
          {t("No bar chart data yet.")}
        </Alert>
      )}
    </div>
  );
}
