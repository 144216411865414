import React, { useState, useEffect } from "react";
import styles from "../modal-styles.module.css";
import { useTranslation } from "react-i18next";
import { Button, Grid, MenuItem, TextField, Select, FormControl, InputLabel } from "@mui/material";
import PriceRangeSelector from "../../Rooms/PriceRangeSlider";

export default function ShaxmatkaFilters({ setShow, show, filterProp, categories, setFilterProp, getDataWithFilter }) {
    const { t } = useTranslation();
    const [showtrue, setShowTrue] = useState(false);
    useEffect(() => setShowTrue(show), [show]);
    const [sideStyle, setSideStyle] = useState({});

    const [filter, setFilter] = useState({
        number: null,
        status: "",
        category: "",
        priceRange: [],
        minValue: 0,
        maxValue: Infinity
    });

    useEffect(() => {
        setFilter(filterProp);
    }, [filterProp]);

    const resize = () => {
        if (window?.innerWidth && window?.innerWidth < 576) setSideStyle({ height: "100%", width: "100%", top: "0" });
        else setSideStyle({ height: "calc(100% - 64px)", width: "480px", top: "64px" });
    };

    useEffect(resize, []);
    window.onresize = resize;

    const close = (e) => {
        if (e.currentTarget === e.target) hide();
    };

    const hide = () => {
        setShowTrue(false);
        setTimeout(() => setShow(false), 300);
    };

    function handleFilter(val) {
        setFilterProp(val);
        hide();
    }

    function handleReset() {
        setFilter((prev) => {
            return {
                number: null,
                status: "",
                priceRange: [prev.minValue || 0, prev.maxValue || Infinity],
                minValue: prev.minValue || 0,
                maxValue: prev.maxValue || Infinity,
                category: ""
            }
        });
    }

    const gridItemSize = 6;

    return (
        <div className={showtrue ? styles.fade : undefined} onClick={close}>
            <div className={styles.modal} style={{ ...sideStyle, right: showtrue ? "0" : "-480px" }}>
                <div className={styles.title}>
                    <span className={styles.close} onClick={hide}>
                        &#x2715;
                    </span>
                </div>
                <div style={{ display: "flex" }}>
                    <Grid container spacing={0} alignItems="center" style={{ paddingTop: 0, alignItems: "flex-start" }}>
                        <Grid item xs={gridItemSize} style={{ paddingTop: 11, margin: 0 }}>
                            <TextField
                                fullWidth
                                type="number"
                                autoComplete="off"
                                label={t("Search by number")}
                                value={filter.number || ""}
                                onChange={(e) => setFilter((prev) => { return { ...prev, number: e.target.value } })}
                                variant="outlined"
                                margin="normal"
                            />
                        </Grid>
                        <Grid item xs={gridItemSize} style={{ paddingLeft: "10px", paddingTop: 11 }}>
                            <FormControl fullWidth margin="normal">
                                <InputLabel>{t("Filter by status")}</InputLabel>
                                <Select
                                    value={filter.status}
                                    label={`${t("Filter by status")}`}
                                    onChange={(e) => setFilter((prev) => { return { ...prev, status: e.target.value } })}
                                >
                                    <MenuItem value="">{t("All")}</MenuItem>
                                    <MenuItem value="available">{t("Available")}</MenuItem>
                                    <MenuItem value="busy">{t("Busy")}</MenuItem>
                                    <MenuItem value="dirty">{t("Dirty")}</MenuItem>
                                    <MenuItem value="cleaning in progress">
                                        {t("Cleaning in progress")}
                                    </MenuItem>
                                    <MenuItem value="in maintains">{t("In maintains")}</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={gridItemSize} style={{ paddingTop: 11 }}>
                            <FormControl fullWidth margin="normal">
                                <InputLabel>{t("Filter by category")}</InputLabel>
                                <Select
                                    value={filter.category}
                                    label={`${t("Filter by status")}`}
                                    onChange={(e) => setFilter((prev) => { return { ...prev, category: e.target.value } })}
                                >
                                    <MenuItem value="">{t("All")}</MenuItem>
                                    {(categories || []).map((category, index) => { return (<MenuItem key={index} value={category.name}>{category.name}</MenuItem>) })
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={gridItemSize} style={{ paddingLeft: "16px" }}>
                            <PriceRangeSelector label={t("Price range")} min={filter.minValue || 0} max={filter.maxValue || filter.priceRange[1]} onChange={(newValue) => {
                                setFilter((prev) => {
                                    return { ...prev, priceRange: newValue }
                                })
                            }} valueProp={filter.priceRange} />
                        </Grid>
                    </Grid>
                </div>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button type="primary" onClick={handleReset}>
                        <span>{t("Reset")}</span>
                    </Button>
                    <Button type="primary" onClick={() => { handleFilter(filter); getDataWithFilter(); }}>
                        <span>{t("Filter")}</span>
                    </Button>
                </div>
            </div>
        </div >
    );
}