import React, { useEffect } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import PersonIcon from "@mui/icons-material/Person";
import LoadingButton from "@mui/lab/LoadingButton";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import { Grid, MenuItem } from "@mui/material";
import axios from "axios";
import { useTranslation } from "react-i18next";
import ENV from "../../environment";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function TransitionsModal({ handleOpenMainModal, isPartner, getData }) {
  const { t } = useTranslation()
  const [roles, setRoles] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const phoneregex = /^([+]?\d{1,3})\s?(\d{2,3}\s?){2,3}\d{0,3}$/;
  const validate = Yup.object({
    first_name: Yup.string().required(t("Field is required")),
    last_name: Yup.string().required(t("Field is required")),
    middle_name: Yup.string().optional(),
    role: Yup.string().required(t("Field is required")),
    email: Yup.string().required(t("Field is required")).email(t("Invalid value")),
    phone_number: Yup.string().matches(phoneregex, { message: t("Invalid value") }).required(t("Field is required")),
  });
  const emptyData = {
    first_name: "",
    last_name: "",
    middle_name: "",
    role: "",
    email: "",
    phone_number: "",
  };

  const onSubmit = async (values) => {
    if (!values?.middle_name?.length) {
      delete values.middle_name;
    }
    const body = {
      first_name: values?.first_name,
      last_name: values?.last_name,
      middle_name: values?.middle_name,
      role: values?.role,
      email: values?.email,
      phone_number: values?.phone_number,
    };
    setLoading(true);
    await axios
      .post(`${ENV.API_URL}/hdp-gateway/user`, body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        setLoading(false);
        toast.success(t("Saved successfully"));
        handleClose();
        getData();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.response?.data?.errors[0]?.message || t("An error occurred"))
      });
  };

  const getRoles = async () => {
    await axios
      .get(`${ENV.API_URL}/hdp-gateway/user/roles`, {
        headers: {
          Authorization: sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        setRoles(res.data.data);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.errors[0]?.message || t("An error occurred"))
        console.log(err.message);
      });
  };
  useEffect(() => {
    getRoles();
  }, []);
  return (
    <div className="modal_res">
      <Button
        onClick={() => {
          if (isPartner) {
            handleOpenMainModal()
            return ""
          }
          handleOpen()
        }}
        variant="contained"
        startIcon={<PersonIcon />}
      >
        {t("Add employee")}
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style} className="modal_res">
            <Typography
              id="transition-modal-title"
              variant="h6"
              component="h2"
              className="marginBottom"
            >
              {t("Enter employee info")}
            </Typography>
            <Formik
              initialValues={emptyData}
              validationSchema={validate}
              onSubmit={async (values) => {
                onSubmit(values);
                return new Promise((res) => setTimeout(res, 500));
              }}
            >
              {({ values, errors }) => (
                <Form autoComplete="off" className="mt-4">
                  <Grid container spacing={4}>
                    <Grid item xs={12}>
                      <Field
                        fullWidth
                        name="first_name"
                        size="small"
                        component={TextField}
                        label={t("First name")}
                        type="text"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        fullWidth
                        name="last_name"
                        size="small"
                        component={TextField}
                        label={t("Last name")}
                        type="text"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        fullWidth
                        name="middle_name"
                        size="small"
                        component={TextField}
                        label={t("Middle name")}
                        type="text"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        fullWidth
                        name="email"
                        size="small"
                        component={TextField}
                        label={t("Email")}
                        type="text"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        fullWidth
                        name="phone_number"
                        size="small"
                        component={TextField}
                        label={t("Phone number")}
                        type="text"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        fullWidth
                        select
                        name="role"
                        size="small"
                        component={TextField}
                        label={t("Select role")}
                      >
                        {roles.map((item, index) => {
                          return (
                            <MenuItem value={item.id} key={index}>
                              {t(item.role)}
                            </MenuItem>
                          );
                        })}
                      </Field>
                    </Grid>
                  </Grid>
                  <div className="right_left">
                    <Button
                      onClick={handleClose}
                      variant="contained"
                      sx={{ mt: 3, mb: 2 }}
                      color="error"
                    >
                      {t("Close")}
                    </Button>
                    <>
                      {loading ? (
                        <LoadingButton
                          loading
                          variant="contained"
                          sx={{ mt: 3, mb: 2 }}
                          color="success"
                        >
                          {t("Submit")}
                        </LoadingButton>
                      ) : (
                        <Button
                          type="submit"
                          variant="contained"
                          sx={{ mt: 3, mb: 2 }}
                          color="success"
                        >
                          {t("Submit")}
                        </Button>
                      )}
                    </>
                    {loading && <div className="overlay" />}
                  </div>
                </Form>
              )}
            </Formik>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

export default React.memo(TransitionsModal);
