import React from "react";
import UsersTable from "../components/Admin-users/UsersTable";

const AdminUsersPage = (props) => {
  const { handleOpenMainModal ,isPartner} = props;

  return <UsersTable  
  handleOpenMainModal={handleOpenMainModal}
  isPartner={isPartner}
   />;
};

export default AdminUsersPage;
