import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import Loading from "../Loading";
import { useTranslation } from "react-i18next";
import ENV from "../../environment";
import { useNavigate, useParams } from "react-router-dom";
import GuestInfo from "../GuestInfo/GuestInfo";
import Button from "@mui/material/Button";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import { LoadingButton } from "@mui/lab";
import { Alert } from "antd";
import { FormControlLabel, Switch } from "@mui/material";
import { dateLocalISO } from "../../hooks/dateFormatter";
import { dateDiffInDays } from "../../hooks/dateDifference";
import { Radio, RadioGroup, Checkbox } from "@mui/material";
// import { CheckBox } from "@mui/icons-material";

const CheckOut = (props) => {
    const navigate = useNavigate();
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);
    const [overlay, setOverlay] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isSwitch, setIsSwitch] = useState(false);
    const { t } = useTranslation();
    let { id } = useParams();
    if (props?.id) {
        id = props?.id;
    }
    const handleSwitch = (event) => {
        setIsSwitch(event.target.checked);
    };
    const getAxios = async () => {
        setIsError(false);
        setLoading(true);
        await axios
            .get(`${ENV.API_URL}/hdp-gateway/check-in/${id}/details`, {
                headers: { authorization: sessionStorage.getItem("token") },
            })
            .then((res) => {
                setData(res.data.data);
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setIsError(true);
                setLoading(false);
            });
    };

    const validate = Yup.object({
        amount: Yup.string().required(t("Field is required")),
    });

    const [radioValue, setRadioValue] = useState(1);
    const handleRadioChange = (event) => {
        setRadioValue(event.target.value);
    };
    const [payment, setPayment] = useState(false);
    const changePayment = (event) => {
        setPayment(event.target.checked);
    };
    var emptyData = { id, amount: 0 };

    const onSubmit = async (values) => {
        setOverlay(true);
        const guestslength = data.guests.length;
        const body = { id: values?.id, credit: radioValue * values?.amount, values, guestslength };
        await axios
            .post(`${ENV.API_URL}/hdp-gateway/check-out`, body, {
                headers: { Authorization: sessionStorage.getItem("token") },
            })
            .then((res) => {
                toast.success(t("Check out successfully"));
                if (props?.handleOpenCheckouttwopage) {
                    props.handleOpenCheckouttwopage();
                } else {
                    navigate(`/check-out/${id}/pdf`);
                }
                setOverlay(false);
                props.getRoomdata();
            })
            .catch((err) => {
                setOverlay(false);
                toast.error(err?.response?.data?.errors[0]?.message || t("An error occurred"));
            });
    };

    useEffect(() => {
        getAxios();
    }, []);

    return (
        <div className="container my-3">
            {overlay && <div className="overlay" />}
            {loading ? (
                <Loading />
            ) : isError ? (
                <Alert message={t("Something went wrong")} type="error" />
            ) : (
                <React.Fragment>
                    <h3>{t("Check out")}</h3>
                    <GuestInfo data={data} id={id} />
                    <div>
                        <Formik initialValues={emptyData} validationSchema={validate} onSubmit={onSubmit}>
                            {({ values, errors, isSubmitting }) => (
                                <Form autoComplete="off">
                                    <div className="row">
                                        <div className="col">
                                            {data.transactions[data.transactions.length - 1].debit ===
                                                data.transactions[data.transactions.length - 1].credit ? (
                                                <div style={{ color: "#3498db", fontWeight: "500" }}>{t("Payment has been made in full by guest")}</div>
                                            ) : data.transactions[data.transactions.length - 1].debit >
                                                data.transactions[data.transactions.length - 1].credit ? (
                                                <div style={{ color: "#e74c3c", fontWeight: "500" }}>{t("Payment has not been made in full by guest")}</div>
                                            ) : data.transactions[data.transactions.length - 1].debit <
                                                data.transactions[data.transactions.length - 1].credit ? (
                                                <div style={{ color: "#e74c3c", fontWeight: "500" }}>{t("An overpayment was made by the guest")}</div>
                                            ) : (
                                                ""
                                            )}
                                            <h6 className="mt-1">
                                                {t("Debit")}: {data.transactions[data.transactions.length - 1].debit}
                                            </h6>
                                            <h6>
                                                {t("Credit")}: {data.transactions[data.transactions.length - 1].credit}
                                            </h6>
                                            {data.end_date?.slice(0, 10) !== dateLocalISO(new Date()) && (
                                                <h6>
                                                    {t("Qaytim")}: {dateDiffInDays(new Date(), new Date(data.end_date?.slice(0, 10))) * data.room_price}{" "}
                                                    <span>({dateDiffInDays(new Date(), new Date(data.end_date?.slice(0, 10)))} kun uchun)</span>
                                                </h6>
                                            )}
                                            {/* <h6>
                                                {t("Qolgan summa")}:{" "}
                                                {data.transactions[data.transactions.length - 1].debit - data.transactions[data.transactions.length - 1].credit}
                                            </h6> */}

                                            <div className="checkout-payment">
                                                <FormControlLabel onChange={changePayment} control={<Checkbox />} label={t("To'lov qo'shish")} />
                                                <div>
                                                    {payment && (
                                                        <React.Fragment>
                                                            <RadioGroup value={radioValue} onChange={handleRadioChange} row>
                                                                <FormControlLabel value={1} control={<Radio />} label={t("Mijoz tomonidan to'lov")} />
                                                                <FormControlLabel value={-1} control={<Radio />} label={t("Mehmonxona tomonidan to'lov")} />
                                                            </RadioGroup>
                                                            {/* <div className="mb-1">{t("Confirm that you have received rest of amount for check out")}</div> */}
                                                            <Field
                                                                style={{ marginRight: "0.5rem", marginTop: "1rem" }}
                                                                label={t("To'lov summasi")}
                                                                type="number"
                                                                name="amount"
                                                                size="small"
                                                                component={TextField}
                                                            />
                                                        </React.Fragment>
                                                    )}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col d-flex flex-column justify-content-between">
                                            <div>
                                                <FormControlLabel onChange={handleSwitch} control={<Checkbox />} label={t("send check to guests email")} />
                                                {isSwitch &&
                                                    data.guests.map((guest, index) => (
                                                        <div>
                                                            <Field
                                                                fullWidth
                                                                style={{ marginRight: "0.5rem", marginBottom: "0.5rem" }}
                                                                required
                                                                type="email"
                                                                defaultValue={guest.email}
                                                                name={`GuestEmail ${index}`}
                                                                label={`${t("Guest")} (${guest.first_name} ${guest.last_name}) ${t("email")}`}
                                                                size="small"
                                                                component={TextField}
                                                            />
                                                        </div>
                                                    ))}
                                            </div>
                                            <div>
                                                {isSubmitting ? (
                                                    <LoadingButton loading variant="contained" color="success">
                                                        {t("Check out")}
                                                    </LoadingButton>
                                                ) : (
                                                    <Button type="submit" variant="contained" color="success">
                                                        {t("Check out")}
                                                    </Button>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="right_left">{isSubmitting && <div className="overlay" />}</div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </React.Fragment>
            )}
        </div>
    );
};

export default CheckOut;
