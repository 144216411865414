import { Alert } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Cell, Legend, Pie, PieChart, Tooltip } from "recharts";

const COLORS = [
  "#a7aeac",
  "#2f6d27",
  "#565656",
  "#cbcb05",
  "#5b73d6",
  "#b3e76f",
];

const PieChartActive = ({ data }) => {
  const { t } = useTranslation();
  return (
    <div className="chart_flex">
      <h5 className="text-primary">{t("Statuses of all rooms")}</h5>
      {data.length ? (
        <PieChart width={350} height={350}>
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            outerRadius={120}
            fill="#8884d8"
            dataKey="value"
            nameKey="label"
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
          <Legend />
          <Tooltip />
        </PieChart>
      ) : (
        <Alert variant="outlined" severity="info">
          {t("No pie chart data yet.")}
        </Alert>
      )}
    </div>
  );
};

export default PieChartActive;
