import { useEffect, useState } from 'react';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, IconButton, Slider, Typography } from "@mui/material";

const PriceRangeSelector = ({ label, min, max, onChange, valueProp }) => {

  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState([min, max]);
  const [minValue, setMinValue] = useState(null);
  const [maxValue, setMaxValue] = useState(null);

  useEffect(() => {
    setValue(valueProp);
  }, [valueProp]);

  const handleChange = (_, newValue) => {
    setValue(newValue);
    setMinValue(newValue[0]);
    setMaxValue(newValue[1]);
  };

  const handleToggle = () => {
    setIsOpen(prev => !prev);
  };

  useEffect(() => {
    onChange(value)
  }, [minValue, maxValue]);

  useEffect(() => {
    setValue([min, max])
    onChange(value)
  }, [min, max]);

  return (
    <Box sx={{ width: "100%" }}>
      <Typography gutterBottom variant="span" onClick={handleToggle} sx={{ cursor: 'pointer', display: 'flex', alignItems: 'center', userSelect: "none" }} mb={"-8px"}>
        {label}
        <IconButton sx={{ ml: 1 }}>
          {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </Typography>
      {isOpen && (
        <Box sx={{ width: "94%", margin: "0 auto" }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 0 }}>
            <Typography>{value[0] || min}</Typography>
            <Typography>{value[1] || max}</Typography>
          </Box>
          <Slider
            value={value}
            onChange={handleChange}
            valueLabelDisplay="auto"
            min={min}
            max={max}
            sx={{ color: 'primary.main' }}
          />
        </Box>
      )}
    </Box>
  );
};

export default PriceRangeSelector;
