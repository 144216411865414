import React from "react";
import { DatePicker, Form, Input, TimePicker, Select } from "antd";

const FormItem = Form.Item;
const { Option } = Select;

const CreateAntField =
    (AntComponent) =>
    ({ field, form, hasFeedback, label, selectOptions, submitCount, type, ...props }) => {
        const submitted = submitCount > 0;

        let touched = form.touched[field.name];
        if (touched === undefined && typeof touched !== "string")
            if (field?.name?.slice(0, 7) === "guests[" && form.touched.guests && form.touched.guests[parseInt(field?.name?.slice(7, 8))])
                touched = form.touched.guests[parseInt(field?.name?.slice(7, 8))][field?.name?.slice(10, field?.name?.length)];

        let hasError = form.errors[field.name];
        if (hasError === undefined && typeof hasError !== "string")
            if (field?.name?.slice(0, 7) === "guests[" && form.errors.guests && form.errors.guests[parseInt(field?.name?.slice(7, 8))])
                hasError = form.errors.guests[parseInt(field?.name?.slice(7, 8))][field?.name?.slice(10, field?.name?.length)];

        const submittedError = hasError && submitted;
        const touchedError = hasError && touched;
        const onInputChange = ({ target: { value } }) => form.setFieldValue(field.name, value);
        const onChange = (value) => form.setFieldValue(field.name, typeof value?.target?.value === "string" ? value.target.value : value);
        const onBlur = () => form.setFieldTouched(field.name, true);
        return (
            <div className="field-container">
                <FormItem
                    label={label}
                    // hasFeedback={(hasFeedback && submitted) || (hasFeedback && touched) ? true : false}
                    help={submittedError || touchedError ? hasError : false}
                    validateStatus={submittedError || touchedError ? "error" : "success"}
                >
                    <AntComponent {...field} {...props} onBlur={onBlur} onChange={type ? onInputChange : onChange} style={{ width: "100%" }}>
                        {selectOptions && selectOptions.map((name) => <Option key={name}>{name}</Option>)}
                    </AntComponent>
                </FormItem>
            </div>
        );
    };

export const AntSelect = CreateAntField(Select);
export const AntDatePicker = CreateAntField(DatePicker);
export const AntInput = CreateAntField(Input);
export const AntTimePicker = CreateAntField(TimePicker);
