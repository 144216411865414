import React, { useState, useEffect } from "react";
import styles from "../modal-styles.module.css";
import corestyles from "../styles.module.css";
import { useTranslation } from "react-i18next";
import { Select } from "antd";

export default function ShaxmatkaSettings({ setShow, show, tableWidthTypes, setTableWidth, tableWidth }) {
    const { t } = useTranslation();
    const [showtrue, setShowTrue] = useState(false);
    useEffect(() => setShowTrue(show), [show]);

    const [sideStyle, setSideStyle] = useState({});
    const resize = () => {
        if (window?.innerWidth && window?.innerWidth < 576) setSideStyle({ height: "100%", width: "100%", top: "0" });
        else setSideStyle({ height: "calc(100% - 64px)", width: "480px", top: "64px" });
    };
    useEffect(resize, []);
    window.onresize = resize;

    const close = (e) => {
        if (e.currentTarget === e.target) hide();
    };

    const hide = () => {
        setShowTrue(false);
        setTimeout(() => setShow(false), 300);
    };

    // const changeHandler = (newValue) => {
    //     const items = [];
    //     items.push(today);
    //     for (let i = 1; i < newValue; i++) items.push(daysLater(today, i));
    //     setDays(items);
    //     setNumberOfDays(newValue);
    // };

    // const changeUp = () => changeHandler(numberOfDays + 1);
    // const changeDown = () => changeHandler(numberOfDays - 1);
    return (
        <div className={showtrue ? styles.fade : undefined} onClick={close}>
            <div className={styles.modal} style={{ ...sideStyle, right: showtrue ? "0" : "-480px" }}>
                <div className={styles.title}>
                    <span className={styles.close} onClick={hide}>
                        &#x2715;
                    </span>
                </div>
                <div className={corestyles.numberDays}>
                    <span>{t("Number of days in table")}:</span>
                    <div className={corestyles.numberArrows}>
                        {show && (
                            <Select
                                defaultValue={tableWidth}
                                onChange={(e) => setTableWidth(e)}
                                options={tableWidthTypes.map((e) => ({ value: e, label: t(e) }))}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

function daysLater(today, difference) {
    return new Date(today.getTime() + difference * 24 * 60 * 60 * 1000);
}
