import React, { useEffect, useState } from "react";
import styles from "../styles.module.css";
import { Dropdown } from "antd";
import cn from "classnames";
import { dateLocalISO } from "../../../hooks/dateFormatter";
import { useTranslation } from "react-i18next";

const ShaxmatkaItemShared = (props) => {
    const { day, data, setGuestsModal, setGuests, room, days } = props;
    const { onBooking, onTransfer, onCheckOut, onCheckIn, onBookingUpdate, onCheckInUpdate } = props;
    const { t } = useTranslation();
    const [currentItems, setCurrentItems] = useState([]);
    const [startedToday, setStartedToday] = useState([]);
    const [endedToday, setEndedToday] = useState([]);
    const [mediumToday, setMediumToday] = useState([]);
    const [startedBeforeToday, setStartedBeforeToday] = useState([]);
    const [menu, setMenu] = useState([]);

    const openGuests = () => {
        const x = [];
        for (let i = 0; i < currentItems.length; i++) {
            currentItems[i].room_id = room.id;
            const find = x.find((e) => e[0].booking_id === currentItems[i].booking_id);
            if (find) find.push(currentItems[i]);
            else x.push([currentItems[i]]);
        }
        setGuests(x);
        setGuestsModal(true);
    };

    useEffect(() => {
        const today = dateLocalISO(day);
        const items = [];
        const booking = currentItems.filter((e) => e.bookingStatus === 2);
        if (new Date() > day || dateLocalISO(new Date()) === today) {
            if (booking.length > 0)
                items.push({
                    children: booking.map((e) => ({
                        label: <span onClick={() => onTransfer(e, room)}>{e.first_name + " " + e.last_name}</span>,
                        key: "5-" + e.guest_id,
                    })),
                    label: <span>{t("Check in")}</span>,
                    key: "5",
                });
        }
        if (booking.length > 0)
            items.push({
                children: booking.map((e) => ({
                    label: <span onClick={() => onBookingUpdate({ ...e, room })}>{e.first_name + " " + e.last_name}</span>,
                    key: "6-" + e.guest_id,
                })),
                label: <span>{t("Update booking")}</span>,
                key: "6",
            });
        if (currentItems.length > 0) items.push({ label: <span onClick={openGuests}>{t("Guests")}</span>, key: "0" });
        const checkin = currentItems.filter((e) => e.bookingStatus === 1);
        if (checkin.length > 0)
            items.push({
                children: checkin.map((e) => ({
                    label: (
                        <span onClick={() => onCheckInUpdate({ ...e, room, guests: checkin.filter((el) => e.booking_id === el.booking_id) })}>
                            {e.first_name + " " + e.last_name}
                        </span>
                    ),
                    key: "7-" + e.guest_id,
                })),
                label: <span>{t("Update")}</span>,
                key: "7",
            });
            if (checkin.length > 0)
                items.push({
                    children: checkin.map((e) => ({
                        label: (
                            <span onClick={() => onTransfer({ ...e, room, guests: checkin.filter((el) => e.booking_id === el.booking_id) })}>
                                {e.first_name + " " + e.last_name}
                            </span>
                        ),
                        key: "8-" + e.guest_id,
                    })),
                    label: <span>{t("Transfer")}</span>,
                    key: "8",
                });
        if (checkin.length > 0) items.push({ label: <span onClick={() => onCheckOut(currentItems)}>{t("Check out")}</span>, key: "3" });
        if (mediumToday.length + startedToday.length - endedToday.length < room.number_of_people) {
            if (new Date() < day || dateLocalISO(new Date()) === today)
                items.push({ label: <span onClick={() => onBooking({ start_date: day, room })}>{t("Booking")}</span>, key: "1" });
            if (dateLocalISO(new Date()) === today) items.push({ label: <span onClick={() => onCheckIn({ room })}>{t("Check in")}</span>, key: "2" });
        }
        setMenu(items);
    }, [currentItems]);

    useEffect(() => {
        refilter();
    }, [data]);

    useEffect(() => {
        refilter();
    }, [day]);

    const refilter = () => {
        const d = dateLocalISO(day);

        const f1 = data.filter((e) => new Date(d) >= new Date(e.start_date.slice(0, 10)) && new Date(d) <= new Date(e.end_date.slice(0, 10)));
        setCurrentItems(f1);

        const f2 = f1.filter((e) => d === e.start_date.slice(0, 10));
        setStartedToday(f2);

        const f4 = f1.filter((e) => d !== e.start_date.slice(0, 10) && d !== e.end_date.slice(0, 10));
        setMediumToday(f4);

        const f5 = f1.filter((e) => d === e.end_date.slice(0, 10));
        setEndedToday(f5);

        if (f1.length !== f2.length + f5.length + f4.length) console.log("xato");

        if (day === days[0]) {
            const f3 = f1.filter((e) => new Date(d) > new Date(e.start_date.slice(0, 10)));
            setStartedBeforeToday(f3);
        }
    };

    return (
        <Dropdown menu={{ items: menu }} trigger={["contextMenu"]}>
            <div className={cn(styles.item)}>
                {/* border uchun */}
                <div
                    className={styles.itemBorder}
                    style={{
                        borderLeftWidth: mediumToday.length === 0 && endedToday.length === 0 ? "1px" : "0",
                        borderRightWidth: mediumToday.length === 0 && startedToday.length === 0 ? "1px" : "0",
                    }}
                ></div>

                {/* jadvaldagi kunlardan oldin boshlanganda */}
                {startedBeforeToday.length > 0 && (
                    <div style={{ width: "50%", backgroundColor: "#ddd" }} className={cn(styles.itemFirstDay)}>
                        <span style={{ fontSize: "0.9em" }}>
                            {`${startedBeforeToday.length} ${startedBeforeToday.length > 1 ? t("c-guests") : t("c-guest")}`}
                        </span>
                    </div>
                )}

                {/* jadvaldagi kunlarda boshlanganda */}
                {(startedToday.length > 0 || mediumToday.length > 0) && (
                    <div
                        style={{
                            width: day === days[days.length - 1] ? "50%" : "100%",
                            backgroundColor: "#ddd",
                            "--bg-color": "transparent",
                        }}
                        className={cn(styles.itemChild)}
                    >
                        <span style={{ fontSize: "0.9em" }}>
                            {`${mediumToday.length + startedToday.length} ${mediumToday.length + startedToday.length > 1 ? t("c-guests") : t("c-guest")}`}
                        </span>
                    </div>
                )}
            </div>
        </Dropdown>
    );
};

export default ShaxmatkaItemShared;
