import React, { useEffect, useState } from "react";
import styles from "../styles.module.css";
import { Dropdown } from "antd";
import cn from "classnames";
import { dateDiffInDays } from "../../../hooks/dateDifference";
import { dateLocalISO } from "../../../hooks/dateFormatter";
import { useTranslation } from "react-i18next";

const ShaxmatkaItem = (props) => {
    const { day, data, setGuestsModal, setGuests, room, days } = props;

    const { onBooking, onTransfer, onCheckOut,onTransferotherRoom, onCheckIn, policy, onBookingUpdate, onBookingCancel, onCheckInUpdate } = props;
    const { t } = useTranslation();
    const [currentItems, setCurrentItems] = useState([]);
    const [startedToday, setStartedToday] = useState([]);
    const [endedToday, setEndedToday] = useState([]);
    const [mediumToday, setMediumToday] = useState([]);
    const [menu, setMenu] = useState([]);

    function timeDifferenceInHours(dateString) {
        const givenDate = new Date(dateString);
        const currentDate = new Date();

        // Calculate the difference in milliseconds
        const diffInMs = Math.abs(currentDate - givenDate);

        // Convert the difference to hours (1 hour = 1000ms * 60s * 60m)
        const diffInHours = diffInMs / (1000 * 60 * 60);

        return diffInHours;
    }

    let cancancel = 0
    if (policy?.cancel_booking == null) {
        cancancel = Infinity
    }
    else if (policy?.cancel_booking !== null) {
        cancancel = policy?.cancel_booking * 24
    }
    console.log(cancancel)


    const openGuests = () => {
        const x = [];
        for (let i = 0; i < currentItems.length; i++) {
            currentItems[i].room_id = room.id;
            const find = x.find((e) => e[0].booking_id === currentItems[i].booking_id);
            if (find) find.push(currentItems[i]);
            else x.push([currentItems[i]]);
        }
        setGuests(x);
        setGuestsModal(true);
    };

    const update = () => {
        const x = [];
        for (let i = 0; i < currentItems.length; i++) {
            currentItems[i].room_id = room.id;
            const find = x.find((e) => e[0].booking_id === currentItems[i].booking_id);
            if (find) find.push(currentItems[i]);
            else x.push([currentItems[i]]);
        }
        setGuests(x);
        setGuestsModal(true);
    };

    useEffect(() => {
        const today = dateLocalISO(day);
        const items = [];
        const booking = currentItems.filter((e) => e.bookingStatus === 2);
        if (new Date() > day || dateLocalISO(new Date()) === today) {
            if (booking.length > 0)
                items.push({
                    children: booking.map((e) => ({
                        label: <span onClick={() => onTransfer(e, room)}>{e.first_name + " " + e.last_name}</span>,
                        key: "5-" + e.guest_id,
                    })),
                    label: <span>{t("Check in")}</span>,
                    key: "5",
                });
        }
        if (booking.length > 0)
            items.push({
                children: booking.map((e) => ({
                    label: <span onClick={() => onBookingUpdate({ ...e, start_date: day, room })}>{e.first_name + " " + e.last_name}</span>,
                    key: "6-" + e.guest_id,
                })),
                label: <span>{t("Update booking")}</span>,
                key: "6",
            });
        if (booking[0]?.booked_by && booking[0]?.bookingBooked_date && timeDifferenceInHours(booking[0]?.bookingBooked_date) < cancancel && booking.length > 0) {
            console.log(timeDifferenceInHours(booking[0]?.bookingBooked_date), cancancel, booking[0])
            items.push({
                children: booking.map((e) => ({
                    label: <span onClick={() => onBookingCancel({ ...e, start_date: day, room })}>{e.first_name + " " + e.last_name}</span>,
                    key: "6-" + e.guest_id,
                })),
                label: <span>{t("Cancel booking")}</span>,
                key: "9",
            });
        }
        if (currentItems.length > 0) items.push({ label: <span onClick={openGuests}>{t("Guests")}</span>, key: "0" });
        const checkin = currentItems.filter((e) => e.bookingStatus === 1);
        if (checkin.length > 0)
            items.push({
                children: checkin.map((e) => ({
                    label: (
                        <span onClick={() => onCheckInUpdate({ ...e, room, guests: checkin.filter((el) => e.booking_id === el.booking_id) })}>
                            {e.first_name + " " + e.last_name}
                        </span>
                    ),
                    key: "7-" + e.guest_id,
                })),
                label: <span>{t("Update")}</span>,
                key: "7",
            });
        if (checkin.length > 0)
            items.push({
                children: checkin.map((e) => ({
                    label: (
                        <span onClick={() => onTransferotherRoom({ ...e, room, guests: checkin.filter((el) => e.booking_id === el.booking_id) })}>
                            {e.first_name + " " + e.last_name}
                        </span>
                    ),
                    key: "8-" + e.guest_id,
                })),
                label: <span>{t("Transfer")}</span>,
                key: "8",
            });
        if (checkin.length > 0) items.push({ label: <span onClick={() => onCheckOut(currentItems)}>{t("Check out")}</span>, key: "3" });
        if (startedToday.length === 0 && mediumToday.length === 0) {
            if ((new Date() < day || dateLocalISO(new Date()) === today) && room.status !== 'in maintains')
                items.push({ label: <span onClick={() => onBooking({ start_date: day, room })}>{t("Booking")}</span>, key: "1" });
            if (dateLocalISO(new Date()) === today && room.status !== 'in maintains') items.push({ label: <span onClick={() => onCheckIn({ room })}>{t("Check in")}</span>, key: "2" });
        }
        setMenu(items);
    }, [currentItems]);

    useEffect(() => {
        refilter();
    }, [data]);

    useEffect(() => {
        refilter();
    }, [day]);

    const [startedTodayC, setStartedTodayC] = useState([]);
    const [startedBeforeTodayC, setStartedBeforeTodayC] = useState([]);
    const refilter = () => {
        const d = dateLocalISO(day);

        const f1 = data.filter((e) => new Date(d) >= new Date(e.start_date.slice(0, 10)) && new Date(d) <= new Date(e.end_date.slice(0, 10)));
        setCurrentItems(f1);

        if (f1.length) console.log(data);

        const f2 = f1.filter((e) => d === e.start_date.slice(0, 10));
        setStartedToday(f2);

        const f22 = f2.filter((e, i) => e.booking_id !== f2[i - 1]?.booking_id);
        // .map((el) => ({ ...el, guests: f2.filter((elem) => elem.booking_id === el.booking_id) }));

        setStartedTodayC(f22);

        const f4 = f1.filter((e) => d !== e.start_date.slice(0, 10) && d !== e.end_date.slice(0, 10));
        setMediumToday(f4);

        const f5 = f1.filter((e) => d === e.end_date.slice(0, 10));
        setEndedToday(f5);

        if (day === days[0]) {
            const f33 = f1.filter((e) => new Date(d) > new Date(e.start_date.slice(0, 10))).filter((e, i) => e.booking_id !== f1[i - 1]?.booking_id);
            // .map((el) => ({ ...el, guests: f3.filter((elem) => elem.booking_id === el.booking_id) }));
            setStartedBeforeTodayC(f33);
        }
    };
    return (
        <Dropdown menu={{ items: menu }} trigger={["contextMenu"]}>
            <div className={cn(styles.item)}>
                {/* border uchun */}
                <div
                    className={styles.itemBorder}
                    style={{
                        borderLeftWidth: mediumToday.length === 0 && endedToday.length === 0 ? "1px" : "0",
                        borderRightWidth: mediumToday.length === 0 && startedToday.length === 0 ? "1px" : "0",
                    }}
                ></div>

                {/* jadvaldagi kunlardan oldin boshlanganda */}
                {startedBeforeTodayC.map((book, i) => (
                    <div
                    title={`${startedBeforeTodayC[0]?.first_name} ${startedBeforeTodayC[0]?.last_name}`}
                        style={{
                            width: widthFD2(days[0], book.end_date, days[days.length - 1], startedBeforeTodayC.length, i + 1),
                            marginLeft: paddingLeft(startedBeforeTodayC.length, i + 1, startedBeforeTodayC),
                            color: textcolor(book.bookingType),
                            textTransform: "capitalize",
                            "--bg-color": book.bookingStatus === 1 && book.notice ? "red" : "transparent",
                        }}
                        className={cn(styles.itemFirstDay, bgcolor(book.bookingType))}
                    >
                        {`${book.first_name} ${book.last_name}`}
                    </div>
                ))}

                {/* jadvaldagi kunlarda boshlanganda */}
                {startedTodayC.map((book, i) => (
                    <div
                        style={{
                            width: width(book.start_date, book.end_date, days[days.length - 1], startedTodayC.length, i + 1),
                            marginLeft: paddingLeft(startedTodayC.length, i + 1),
                            color: textcolor(book.bookingType),
                            textTransform: "capitalize",
                            "--bg-color": book.bookingStatus === 1 && book.notice ? "red" : "transparent",
                        }}
                        className={cn(styles.itemChild, bgcolor(book.bookingType))}
                    >
                        {`${book.first_name} ${book.last_name}`}
                    </div>
                ))}
            </div>
        </Dropdown>
    );
};

export default ShaxmatkaItem;

function textcolor(bookingType) {
    if (bookingType === 1) return "white";
    if (bookingType === 2) return "white";
    if (bookingType === 3) return "white";
    if (bookingType === 4) return "black";
    if (bookingType === 5) return "white";
    return "";
}

function bgcolor(bookingType) {
    if (bookingType === 1) return "green";
    if (bookingType === 2) return "purple";
    if (bookingType === 3) return "blue";
    if (bookingType === 4) return "yellow";
    if (bookingType === 5) return "orange";
    return "";
}

function width(start_date, end_date_1, end_date_2, len, index) {
    if (len === 1 && start_date === end_date_1) return "35%";

    if (len > 1) {
        if (index !== len) {
            return 100 / (len + 1) + "%";
        } else {
            const diff1 = dateDiffInDays(new Date(start_date), new Date(end_date_1));
            const diff2 = dateDiffInDays(new Date(start_date), new Date(dateLocalISO(end_date_2)));
            if (diff1 > diff2) return diff2 * 100 + 50 - 100 / (len + 1) + "%";
            return diff1 * 100 - 100 / (len + 1) + "%";
        }
    } else {
        const diff1 = dateDiffInDays(new Date(start_date), new Date(end_date_1));
        const diff2 = dateDiffInDays(new Date(start_date), new Date(dateLocalISO(end_date_2)));
        if (diff1 > diff2) return diff2 * 100 + 50 + "%";
        return diff1 * 100 + "%";
    }
}

function paddingLeft(len, index) {
    if (len > 1 && index !== 1) {
        return 100 / (index + 1) + "%";
    }
}

function widthFD2(start_date, end_date_1, end_date_2, len, index) {
    if (len > 1) {
        if (index !== len) {
            return 100 / (len + 1) + "%";
        } else {
            const diff1 = dateDiffInDays(new Date(dateLocalISO(start_date)), new Date(end_date_1));
            const diff2 = dateDiffInDays(new Date(dateLocalISO(start_date)), new Date(end_date_2));
            if (diff1 > diff2) return diff2 * 100 + 100 - 100 / (len + 1) + "%";
            return diff1 * 100 + 50 - 100 / (len + 1) + "%";
        }
    } else {
        const diff1 = dateDiffInDays(new Date(dateLocalISO(start_date)), new Date(end_date_1));
        const diff2 = dateDiffInDays(new Date(dateLocalISO(start_date)), new Date(end_date_2));
        if (diff1 > diff2) return diff2 * 100 + 100 + "%";
        return diff1 * 100 + 50 + "%";
    }
}
