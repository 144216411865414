import React, { useEffect, useState } from "react";
import { Button, Modal } from "antd";
import axios from "axios";
import ENV from "../../environment";
import Loading from "../Loading";
import { Alert } from "antd";
import { useTranslation } from "react-i18next";

const OpenDocument = ({ disabled, guest }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [src, setsrc] = useState(null);
    const [wait, setWait] = useState(true);
    const [isError, setIsError] = useState(false);
    const [notPDF, setNotPDF] = useState(false);
    const { t } = useTranslation();

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const getFile = async () => {
        setWait(true);
        setNotPDF(false);
        setIsError(false);
        
        if(!guest?.document_attachment) return;

        await axios(`${ENV.API_URL}/hdp-gateway/file/${guest?.document_attachment}`, {
            headers: { Authorization: sessionStorage.getItem("token") },
            responseType: "blob",
        })
            .then((blob) => {
                if (blob.data.type === "application/pdf") setTimeout(() => setsrc(window.URL.createObjectURL(blob.data)));
                else setNotPDF(true);
            })
            .catch((err) => {
                setIsError(true);
                console.log(err);
            });
        setWait(false);
    };

    useEffect(() => {
        getFile();
    }, []);

    return (
        <>
            <Button onClick={showModal} disabled={disabled}>
                {t("view document")}
            </Button>
            <Modal title={t("Document")} open={isModalOpen} footer={[]} onCancel={handleCancel} width={1000}>
                {wait ? (
                    <Loading />
                ) : isError ? (
                    <Alert message={t("Something went wrong")} type="error" />
                ) : notPDF ? (
                    <Alert message={t("File could not be read")} type="warning" />
                ) : (
                    <iframe src={src} frameborder="0" style={{ width: "952px", maxWidth: "calc(100vw - 80px)", height: "600px" }} />
                )}
            </Modal>
        </>
    );
};
export default OpenDocument;
