import React, { useState, useEffect } from "react";
import styles from "../modal-styles.module.css";
import styles2 from "./booking-styles.module.css";
import { Button, Input } from "antd";
import { useTranslation } from "react-i18next";
import { Field, Form, Formik } from "formik";
import { AntDatePicker, AntInput, AntSelect } from "../../../hooks/createAntDFields";
// import { bookingInitialValue as initialValue } from "./initialValue";
import * as Yup from "yup";
import { toast } from "react-toastify";
import axios from "axios";
import ENV from "../../../environment";
import { dateDDMMYYYY, dateLocalISO } from "../../../hooks/dateFormatter";

export default function ShaxmatkaBooking({ setShow, show, bookingTypes, bookingData, getData }) {
    const { t } = useTranslation();
    const validate = Yup.object({
        first_name: Yup.string().required(t("Field is required")),
        last_name: Yup.string().required(t("Field is required")),
        room_category: Yup.number().required(t("Field is required")),
        room_id: Yup.number().required(t("Field is required")),
        start_date: Yup.string().required(t("Field is required")),
        end_date: Yup.object().required(t("Field is required")),
        type: Yup.number().required(t("Field is required")),
        debit: Yup.number().required(t("Field is required")),
        credit: Yup.number(),
        comments: Yup.string(),
    });
    const initialValues = {
        first_name: undefined,
        last_name: undefined,
        start_date: undefined,
        end_date: undefined,
        room_category: undefined,
        room_id: undefined,
        debit: undefined,
        credit: undefined,
        type: undefined,
        comments: undefined,
    };

    const change = (values) => {
        if (bookingData.start_date) values.start_date = dateLocalISO(bookingData.start_date);
        if (bookingData.room?.category.id) values.room_category = bookingData.room?.category.id;
        if (bookingData.room?.id) values.room_id = bookingData.room?.id;
        if (values.end_date?.$d && bookingData.start_date)
            values.debit = bookingData.room?.price * dateDiffInDays(bookingData.start_date, values.end_date?.$d) || 0;
    };

    const types = bookingTypes.map((e) => ({ value: e.id, label: e.name }));
    const [showtrue, setShowTrue] = useState(false);

    useEffect(() => setShowTrue(show), [show]);

    const [sideStyle, setSideStyle] = useState({});
    const resize = () => {
        if (window?.innerWidth && window?.innerWidth < 576) setSideStyle({ height: "100%", width: "100%", top: "0" });
        else setSideStyle({ height: "calc(100% - 64px)", width: "480px", top: "64px" });
    };
    useEffect(resize, []);
    window.onresize = resize;

    const close = (e) => {
        // if (e.currentTarget === e.target) hide();
    };
    const hide = (resetForm) => {
        setShowTrue(false);
        if (resetForm) resetForm();
        setTimeout(() => setShow(false), 300);
    };

    const submit = async (data, { resetForm }, setSubmitting) => {
        if (!data.end_date.$d || new Date(dateLocalISO(new Date(data?.start_date))) >= new Date(dateLocalISO(data.end_date?.$d)))
            return toast.error("End date is invalid");

        if (!data?.comments) delete data.comments;
        if (!data?.credit) delete data.credit;
        const body = { ...data, end_date: dateLocalISO(data.end_date?.$d) };
        await axios
            .post(`${ENV.API_URL}/hdp-gateway/booking`, body, {
                headers: { Authorization: sessionStorage.getItem("token") },
            })
            .then(() => {
                toast.success(t("Success"));
                resetForm();
                setShow(false);
                getData();
            })
            .catch((err) => {
                console.log(err);
                toast.error(t("An error occurred"));
            });
        setSubmitting(false);
    };

    return (
        <div className={showtrue ? styles.fade : undefined} onClick={close}>
            <Formik initialValues={initialValues} validationSchema={validate} onSubmit={submit}>
                {({ values, errors, touched, isSubmitting, submitCount, resetForm }) => (
                    <div className={styles.modal} style={{ ...sideStyle, right: showtrue ? "0" : "-480px" }}>
                        <div className={styles.title}>
                            <span className={styles.close} onClick={() => hide(resetForm)}>
                                &#x2715;
                            </span>
                        </div>
                        <h5 style={{ padding: "10px" }}>{t("Booking")}</h5>
                        <div></div>
                        <Form autoComplete="off">
                            {change(values)}
                            <div className={`${styles2.bookingContainer} bookingContainer`}>
                                <div className={styles2.bookingKey}>{t("Category")}</div>
                                <Field component={AntInput} value={bookingData.room?.category.name} submitCount={submitCount} readOnly />
                                <div className={styles2.bookingKey}>{t("Room")}</div>
                                <Field component={AntInput} value={bookingData.room?.number} submitCount={submitCount} readOnly />
                                <div className={styles2.bookingKey}>{t("Start date")}</div>
                                <Field component={AntInput} name="start_date" value={dateDDMMYYYY(bookingData.start_date)} submitCount={submitCount} readOnly />
                                <div className={styles2.bookingKey}>{t("End date")}</div>
                                <Field
                                    component={AntDatePicker}
                                    name="end_date"
                                    onChange={(e) => (values.end_date = e?.$d)}
                                    submitCount={submitCount}
                                    format={"DD.MM.YYYY"}
                                />
                                <div className={styles2.bookingKey}>{t("Booking type")}</div>
                                <Field component={AntSelect} name="type" submitCount={submitCount} options={types} />
                                <hr />
                                <hr />
                                <div className={styles2.bookingKey}>{t("First name")}</div>
                                <Field component={AntInput} name="first_name" submitCount={submitCount} />
                                <div className={styles2.bookingKey}>{t("Last name")}</div>
                                <Field component={AntInput} name="last_name" submitCount={submitCount} />
                                <hr />
                                <hr />
                                <div className={styles2.bookingKey}>{t("Amount")}</div>
                                <Field component={AntInput} name="debit" submitCount={submitCount} readOnly />
                                <div className={styles2.bookingKey}>{t("Credit")}</div>
                                <Field component={AntInput} name="credit" submitCount={submitCount} />
                                <div className={styles2.bookingKey}>{t("Comment")}</div>
                                <Field component={AntInput} name="comments" submitCount={submitCount} />
                            </div>
                            <Button style={{ float: "right", marginRight: "10px" }} type="primary" htmlType="submit">
                                {isSubmitting ? t("Submitting") : t("Submit")}
                            </Button>
                        </Form>
                    </div>
                )}
            </Formik>
        </div>
    );
}

function dateDiffInDays(a, b) {
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;
    // Discard the time and time-zone information.
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}
