import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import { createGet } from "../Context/GetRolesContext";
import { useTranslation } from "react-i18next";
import LogoutIcon from "@mui/icons-material/Logout";
import { toast } from "react-toastify";
import { Select } from "@mui/material";
import en from "../../assets/br.png";
import uz from "../../assets/uz.png";
import ru from "../../assets/ru.png";
import Cookies from "js-cookie";

const App = ({ first_name, last_name }) => {

  const [get, setGet] = useContext(createGet);
  const { t, i18n } = useTranslation();
  const [lang, setLang] = useState(localStorage.getItem("i18nextLng") || Cookies.get("i18next") || "en");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);
  
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const navigate = useNavigate();
  const Logout = () => {
    sessionStorage.clear();
    navigate("/login");
    toast.success(t("Logout successfully"));
  };

  const changeLanguage = (e) => {
    setGet((prev) => !prev);
    Cookies.set("i18next", e.target.value);
    i18n.changeLanguage(e?.target?.value);
    setLang(e?.target?.value);
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      id={menuId}
      anchorEl={anchorEl}
      keepMounted
      open={isMenuOpen}
      onClose={handleMenuClose}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <Link to="/profile">
        <MenuItem onClick={handleMenuClose}>
          {t("profile")}
          <span className="w-25 " />
          <AccountCircleOutlinedIcon />
        </MenuItem>
      </Link>
      <MenuItem onClick={handleMenuClose}>
        <div onClick={Logout}>
          {t("logout")}
          <span className="w-25 " />
          <LogoutIcon />
        </div>
      </MenuItem>
    </Menu>
  );

  return (
    <div className="navbar_wrapper">
      <div className="navbar_personal_title">
        <span>{first_name} {last_name}</span>
      </div>
      <div>
        <Select
          value={lang}
          defaultValue={lang}
          size="small"
          onChange={changeLanguage}
          style={{ color: "white" }}
        >
          <MenuItem value="en">
            <img style={{ width: "23px", marginRight: "7px" }} src={en} alt="en" />{" "} en
          </MenuItem>
          <MenuItem value="uz">
            <img style={{ width: "23px", marginRight: "7px" }} src={uz} alt="uz" />{" "} uz
          </MenuItem>
          <MenuItem value="ru">
            <img style={{ width: "23px", marginRight: "7px" }} src={ru} alt="uz" />{" "} ru
          </MenuItem>
        </Select>
      </div>
      <IconButton
        size="large"
        edge="end"
        aria-label="account of current user"
        aria-controls={menuId}
        aria-haspopup="true"
        onClick={handleProfileMenuOpen}
        color="inherit"
      >
        <AccountCircle className="mui_icon" />
      </IconButton>
      {renderMenu}
    </div>
  );
};
export default App;
