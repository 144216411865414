import React from "react";
import { useParams } from "react-router-dom";
import DiscountTable from "./DiscountTable";

const DisCount = () => {
  const { id, category_name } = useParams();
  return (
    <div>
      <DiscountTable/>
    </div>
  );
};

export default DisCount;
