import { createContext, useState } from "react";

export const createGet = createContext();

export const GetProvider = ({ children }) => {
  const [get, setGet] = useState(false);
  return (
    <createGet.Provider value={[get, setGet]}>
      {children}
    </createGet.Provider>
  );
};
