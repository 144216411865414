import { useState } from "react";
import { Button, Card, CardContent } from "@mui/material";
import { Grid } from "@mui/material";
import { CircularProgress } from "@mui/material";
import { TextField } from "formik-material-ui";
import { Field, Form, Formik } from "formik";
import { toast } from "react-toastify";
import axios from "axios";
import { personalSchema } from "./ValidationSchema.js";
import { personalData } from "./EmptyData.js";
import ENV from "../../environment";
import { useTranslation } from "react-i18next";

export default function Personal({ setStep, token }) {
  const {t} = useTranslation()
  const [loading, setLoading] = useState(false);
  const submit = async (data) => {
    setLoading(true);
    if (!data?.middle_name?.length) data.middle_name = null;

    await axios
      .post(`${ENV.API_URL}/hdp-gateway/fth/personal`, data, {
        headers: { Authorization: token },
      })
      .then(() => {
        setLoading(false);
        setStep("address");
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.response?.data?.errors[0]?.message || t("An error occurred"))
      });
  };

  return (
    <>
      {
        <div className="d-flex justify-content-center flex-column container py-3">
          <div className="py-2">
            <div className="card">
              <div className="card-header">
                <h5 className="text-center">Personal</h5>
                {loading && <div className="overlay"/>}
              </div>
              <div>
                <Card>
                  <CardContent style={{ padding: "24px" }}>
                    <Formik
                      initialValues={personalData}
                      validationSchema={personalSchema}
                      onSubmit={async (values) => {
                        submit(values);
                        return new Promise((res) => setTimeout(res, 100));
                      }}
                    >
                      {({ values, errors, touched, isSubmitting }) => (
                        <Form autoComplete="off">
                          <Grid container direction="column" spacing={2}>
                            <Grid item container spacing={2} xs={12}>
                              <Grid item xs={12} sm={6}>
                                <Field
                                  fullWidth
                                  size="small"
                                  name="first_name"
                                  label="First name"
                                  autoComplete="first_name"
                                  component={TextField}
                                />
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <Field
                                  fullWidth
                                  size="small"
                                  name="last_name"
                                  label="Last name"
                                  autoComplete="last_name"
                                  component={TextField}
                                />
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <Field
                                  fullWidth
                                  size="small"
                                  name="middle_name"
                                  label="Middle name"
                                  autoComplete="middle_name"
                                  component={TextField}
                                />
                              </Grid>

                              <Grid item xs={12} sm={6}>
                                <Field
                                  fullWidth
                                  size="small"
                                  name="password"
                                  label="Password"
                                  autoComplete="password"
                                  component={TextField}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <Button
                                  type="submit"
                                  variant="contained"
                                  className="float-end"
                                  disabled={loading}
                                  startIcon={
                                    loading && (
                                      <CircularProgress size="0.9rem" />
                                    )
                                  }
                                >
                                  Next
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Form>
                      )}
                    </Formik>
                  </CardContent>
                </Card>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
}
