import React, { useEffect, useState } from "react";
import axios from "axios";
import ENV from "../../environment";
import Loading from "../Loading";
import { Alert } from "antd";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { MenuItem, Select } from "@mui/material";
import en from "../../assets/br.png";
import uz from "../../assets/uz.png";
import ru from "../../assets/ru.png";

const CheckoutPDF = (props) => {
    let { id } = useParams();
    const [src, setsrc] = useState(null);
    const [wait, setWait] = useState(true);
    const [isError, setIsError] = useState(false);
    const [language, setLanguage] = useState(localStorage.getItem("i18nextLng") || "en");
    const [notPDF, setNotPDF] = useState(false);
    const { t } = useTranslation();

    const changeLanguage = (e) => {
        setsrc(null);
        setLanguage(e.target.value);
    };
    if (props?.id) {
        id = props.id
    }
    const getFile = async () => {
        setWait(true);
        setNotPDF(false);
        setIsError(false);
        await axios(`${ENV.API_URL}/hdp-gateway/check-out/pdf/${id}?lan=${language}`, {
            headers: { Authorization: sessionStorage.getItem("token") },
            responseType: "blob",
        })
            .then((blob) => {
                if (blob.data.type === "application/pdf") setTimeout(() => setsrc(window.URL.createObjectURL(blob.data)));
                else setNotPDF(true);
            })
            .catch((err) => {
                setIsError(true);
                console.log(err);
            });
        setWait(false);
    };

    useEffect(() => {
        getFile();
    }, [language]);

    return (
        <div className="container mt-5">
            <div style={{ float: "right" }}>
                <Select value={language} defaultValue={language} size="small" onChange={changeLanguage}>
                    <MenuItem value="en">
                        <img style={{ width: "23px", marginRight: "7px" }} src={en} alt="en" /> en
                    </MenuItem>
                    <MenuItem value="uz">
                        <img style={{ width: "23px", marginRight: "7px" }} src={uz} alt="uz" /> uz
                    </MenuItem>
                    <MenuItem value="ru">
                        <img style={{ width: "23px", marginRight: "7px" }} src={ru} alt="uz" /> ru
                    </MenuItem>
                </Select>
            </div>

            <div>
                {wait ? (
                    <Loading />
                ) : isError ? (
                    <Alert message={t("Something went wrong")} type="error" />
                ) : notPDF ? (
                    <Alert message={t("File could not be read")} type="warning" />
                ) : (
                    <iframe src={src} frameborder="0" style={{ width: "952px", maxWidth: "calc(100vw - 80px)", height: "600px" }} />
                )}
            </div>
        </div>
    );
};
export default CheckoutPDF;
