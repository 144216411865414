import styled from "styled-components";

export const FormControlWrapper = styled.div`
    margin-top: 50px;
    min-width: 20%;
    position: relative;
    @media only screen and (max-width: 350px) {
        margin-top: 20px;
    }
`;

export const FormControls = styled.div`
    max-width: 500px;
    min-width: 100px;
    margin-left: auto;
    margin-right: 20px;
    @media only screen and (max-width: 1400px) {
        margin-right: 0px;
    }
`;
export const ListBtn = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 50px;
`;
