import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Popper from '@mui/material/Popper';
import Paper from '@mui/material/Paper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { useTranslation } from 'react-i18next';
import { Box, FormControl, IconButton, InputLabel, Modal, Select, Typography } from '@mui/material';
import CloseIcon from "@mui/icons-material/Close";
import { FormControls } from '../Housekeeping/Supervisor-assignment/ListStyle';
import axios from 'axios';
import ENV from "../../environment";

const CleaningButtonGroup = ({ onRoomAssignment, fetchData, housekeepers, roomhousekeeper, room_id, disabled }) => {
  const { t } = useTranslation();
  const [openroomsAsg, setOpenroomsAsg] = useState(false);
  const handleOpenroomsAsg = () => setOpenroomsAsg(true);
  const handleCloseroomsAsg = () => setOpenroomsAsg(false);

  // Correctly filter the roomhousekeeper array
  const filteredRoomHousekeeper = roomhousekeeper.filter((rh) => rh.room_id === room_id);
  const [openroomsavil, setOpenroomsavil] = useState(false);
  const handleOpenroomsavil = () => setOpenroomsavil(true);
  const handleCloseroomsavil = () => setOpenroomsavil(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  // State to hold the selected housekeeper
  const [selectedHousekeeper, setSelectedHousekeeper] = useState('');

  const handleSelectChange = (event) => {
    setSelectedHousekeeper(event.target.value);
  };

  const handleasgroom = () => {
    const token = sessionStorage.getItem("token");
    axios.patch(`${ENV.API_URL}/hdp-gateway/user/room_assignment`, { staff_id: selectedHousekeeper, rooms: [room_id] }, {
      headers: { Authorization: token },
    });
    handleCloseroomsAsg();
    fetchData();
  };

  const handleavilroom = () => {
    const token = sessionStorage.getItem("token");
    if (filteredRoomHousekeeper.length > 0) {
      axios.delete(`${ENV.API_URL}/hdp-gateway/user/housekeeper/${filteredRoomHousekeeper[0].id}`, {
        headers: { Authorization: token },
      });
      handleCloseroomsAsg();
      fetchData();
    }
  };

  return (
    <div>
      <MenuItem
        disabled={disabled}
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        Cleaning
      </MenuItem>
      <Popper
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        placement="right"
        style={{ zIndex: 1300 }}
      >
        <Paper>
          <MenuList>
            <MenuItem onClick={handleOpenroomsAsg} >{t("Room assignment")}</MenuItem>
            <MenuItem onClick={handleOpenroomsavil} disabled={filteredRoomHousekeeper.length === 0}>{t("Available")}</MenuItem>
          </MenuList>
        </Paper>
      </Popper>

      {/* Room assignment modal */}
      <Modal
        open={openroomsAsg}
        onClose={handleCloseroomsAsg}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        BackdropProps={{ onClick: handleCloseroomsAsg }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: '8px',
          }}
        >
          <IconButton
            aria-label="close"
            onClick={handleCloseroomsAsg}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          {filteredRoomHousekeeper.length > 0 && (
            <div variant="h6" component="h6" gutterBottom>
              {t("You have already selected a housekeeper")}{" "}
              {`${filteredRoomHousekeeper[0].first_name} ${filteredRoomHousekeeper[0].last_name}`} {" "}
              {t("Do you want to select a different one?")}
            </div>
          )}

          <Typography variant="h6" component="h2" gutterBottom>
            {t("Select Housekeeper")}
          </Typography>
          <FormControls>

            <FormControl style={{ width: "500px" }}>
              <InputLabel id="demo-simple-select-label">
                {t("Select Housekeeper")}
              </InputLabel>

              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedHousekeeper}
                label={t("Select Housekeeper")}
                onChange={handleSelectChange}
                fullWidth
              >
                {housekeepers.map((value, index) => (
                  <MenuItem key={index} value={value.id}>
                    {value.first_name} {value.last_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </FormControls>
          <Button
            variant="contained"
            color="primary"
            onClick={handleasgroom}
            fullWidth
            style={{ marginTop: '16px' }}
          >
            Save
          </Button>
        </Box>
      </Modal>

      {/* Room availability modal */}
      <Modal
        open={openroomsavil}
        onClose={handleCloseroomsavil}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        BackdropProps={{ onClick: handleCloseroomsavil }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: '8px',
          }}
        >
          <IconButton
            aria-label="close"
            onClick={handleCloseroomsavil}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" component="h2" gutterBottom>
            {t("Are you sure you want to cancel this room to available status?")}
          </Typography>
          <div style={{ display: 'flex', gap: "20px" }}>
            <Button
              variant="contained"
              color="error"
              fullWidth
              style={{ marginTop: '16px' }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleavilroom}
              fullWidth
              style={{ marginTop: '16px' }}
            >
              Save
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default CleaningButtonGroup;
