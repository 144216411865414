import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { dateTime } from "../../hooks/dateFormatter";
import { useTranslation } from "react-i18next";

export default function BasicTable({ data }) {
    const { t } = useTranslation();
    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>{t("Payment by")}</TableCell>
                        <TableCell align="left">{t("Payment type")}</TableCell>
                        <TableCell align="left">{t("Payment time")}</TableCell>
                        <TableCell align="right">{t("Debit")}</TableCell>
                        <TableCell align="right">{t("Credit")}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data?.map((transaction) => (
                        <TableRow key={transaction.guest || "all"} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                            <TableCell component="th" scope="row">
                                {transaction.guest}
                            </TableCell>
                            <TableCell align="left">{transaction.transaction_type}</TableCell>
                            <TableCell align="left">{dateTime(transaction.transaction_date)}</TableCell>
                            <TableCell align="right">{transaction.debit || 0}</TableCell>
                            <TableCell align="right">{transaction.credit || 0}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
