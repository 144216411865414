export const emptyGuest = {
  document_attachment: "",
  first_name: "",
  last_name: "",
  email: "",
  phone: "",
  document_type: "",
  document: "",
};

export const initialEmpty = {
  room_category: "",
  room_id: "",
  start_date: "",
  end_date: "",
  amount: "",
  credit: "",
  discount:"",
  comments: "",
  guests: [emptyGuest],
};
