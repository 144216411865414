import React from "react";
import StepOne from "./StepOne";
import StepSecond from "./StepSecond";
import Register from './Register'

const Login = () => {
  const login = sessionStorage.getItem("login");
  return <div>{login ? <StepSecond /> : <StepOne />}</div>;
};

export default Login;
