export const dateDDMMYYYY = (date) => {
    if (!date) return "";
    if (typeof date !== "string") date = dateLocalISO(date);
    return `${date.slice(8, 10)}.${date.slice(5, 7)}.${date.slice(0, 4)}`;
};

export const dateDDMMYY = (date) => {
    if (!date) return "";
    const offset = date.getTimezoneOffset();
    date = new Date(date.getTime() - offset * 60 * 1000);
    return `${date.toISOString().slice(8, 10)}.${date.toISOString().slice(5, 7)}.${date.toISOString().slice(0, 4)}`;
};

export const dateMonth = (date) => {
    if (!date) return "";
    const offset = date.getTimezoneOffset();
    date = new Date(date.getTime() - offset * 60 * 1000);

    switch (date.toISOString().slice(5, 7)) {
        case "01":
            return "January";
        case "02":
            return "February";
        case "03":
            return "March";
        case "04":
            return "April";
        case "05":
            return "May";
        case "06":
            return "June";
        case "07":
            return "July";
        case "08":
            return "August";
        case "09":
            return "September";
        case "10":
            return "October";
        case "11":
            return "November";
        case "12":
            return "December";

        default:
            return "";
    }
};

export const dateWeekDay = (date) => {
    if (!date) return "";
    const offset = date.getTimezoneOffset();
    date = new Date(date.getTime() - offset * 60 * 1000);

    switch (date.getDay()) {
        case 1:
            return "Monday";
        case 2:
            return "Tuesday";
        case 3:
            return "Wednesday";
        case 4:
            return "Thursday";
        case 5:
            return "Friday";
        case 6:
            return "Saturday";
        case 0:
            return "Sunday";

        default:
            return "";
    }
};

export const dateLocalISO = (date) => {
    if (!date) return "";
    const offset = date.getTimezoneOffset();
    date = new Date(date.getTime() - offset * 60 * 1000);
    return date.toISOString().slice(0, 10);
};

export const dateTime = (date) => {
    if (!date) return "";
    const newDate = new Date(date);
    const offset = newDate.getTimezoneOffset();
    date = new Date(newDate.getTime() - offset * 60 * 1000);
    return `${date.toISOString().slice(8, 10)}.${date.toISOString().slice(5, 7)}.${date.toISOString().slice(0, 4)} ${date.toISOString().slice(11, 16)}`;
};
