import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";
import axios from "axios";
import Alert from "@mui/material/Alert";
import ViewModal from './ViewModal'
import Tablee from "./Table";
import Loading from "../../Loading";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import ENV from "../../../environment";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) return -1;
  if (b[orderBy] > a[orderBy]) return 1;
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { t } = useTranslation()
  const headCells = [
    { id: "first_name", numeric: true, label: t("Housekeeper name") },
    { id: "quantity", numeric: true, label: t("Quantity") },
  ];

  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};
const TableWIthBtn = () => {
  const { t } = useTranslation()
  const [loadingBtn, setLoadingBtn] = React.useState(false);
  const [idx, setIdx] = React.useState([]);
  const [data, setData] = React.useState([]);
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const getData = async () => {
    setLoading(true);
    await axios
      .get(`${ENV.API_URL}/hdp-gateway/user/housekeeper-rooms`, {
        headers: {
          Authorization: sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        setLoading(false);
        setData(res.data.data);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.response?.data?.errors[0]?.message || t("An error occurred"))
        setError(true);
      });
  };
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const openMenu = (event, elem) => {
    setIdx(elem);
  };
  const month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  const d = new Date();
  const mouth = month[d.getMonth()];
  const year = new Date().getFullYear();
  const day = new Date().getDate();
  React.useEffect(() => {
    getData();
  }, []);
  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className="container mt-5 d-flex TableWIthBtn">
          {loadingBtn && <div className="overlay" />}
          <div className="flex-grow-1">
            <h5>{year + " " + t(mouth) + " " + day}</h5>
            <Box sx={{ width: "100%", marginTop: "20px" }}>
              {!error && data.length === 0 && (
                <Alert variant="outlined" severity="warning">
                  {t("No Data yet")}
                </Alert>
              )}
              {error && (
                <Alert variant="outlined" severity="error">
                  {t("An error occurred")}
                </Alert>
              )}
              {data.length >= 1 && (
                <Paper sx={{ width: "100%", mb: 2 }}>
                  <TableContainer>
                    <Table
                      sx={{ minWidth: 750, px: 2 }}
                      aria-labelledby="tableTitle"
                      size="medium"
                    >
                      <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        rowCount={data.length}
                      />
                      <TableBody>
                        {stableSort(data, getComparator(order, orderBy))
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((row, index) => {
                            return (
                              <TableRow
                                onClick={(e, elem = row) => openMenu(e, elem)}
                                hover
                                tabIndex={-1}
                                key={row.id}
                              >
                                <TableCell align="left">
                                  {row.first_name} {row.last_name}
                                </TableCell>
                                <TableCell align="left">
                                  {row.quantity}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 100]}
                    component="div"
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Paper>
              )}
            </Box>
          </div>
          <div className="flex-grow-1 TableWIthBtn_item_right d-flex justify-content-center align-items-center">
            <ViewModal />
          </div>
        </div>
      )}
      <Tablee data={idx} getData={getData} />
    </>
  );
};

export default TableWIthBtn;
