import React, { useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import { Alert, Grid, MenuItem } from "@mui/material";
import axios from "axios";
import { LoadingButton } from "@mui/lab";
import Loading from "../Loading.jsx";
import { useTranslation } from "react-i18next";
import ENV from "../../environment.js";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function TransitionsModal({ getTableData, close, row }) {
  const { t } = useTranslation()
  const [rooms, setRooms] = React.useState([]);
  const [price, setPrice] = React.useState(row?.amount);
  const [types, setTypes] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [wait, setWait] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const allRegEx = /^[A-Za-z]{1,1}[a-zA-Z0-9]{0,}[a-z0-9]{1,}$/;

  const validate = Yup.object({
    first_name: Yup.string()
      .required("first_name is required")
      .matches(allRegEx, { message: "enter valid value" }),
    last_name: Yup.string()
      .required("last_name is required")
      .matches(allRegEx, { message: "enter valid value" }),
    room_id: Yup.number().required("room is required"),
    start_date: Yup.string().required("start_date is required"),
    end_date: Yup.string().required("end_date is required"),
    type: Yup.number().required("type is required"),
    amount: Yup.number().required("amount is required"),
    comments: Yup.string().optional("comments is required").nullable(),
  });

  var Data = {
    first_name: row?.first_name,
    last_name: row?.last_name,
    room_id: row?.room_id,
    type: row?.type,
    start_date: row?.start_date?.slice(0, 10),
    end_date: row?.end_date?.slice(0, 10),
    amount: row?.amount,
    comments: row?.comments
  };

  const onUpdate = async (values) => {
    values.id = row?.id
    setLoading(true);
    if (values?.status) {
      delete values.status;
    }
    if (!values?.difference?.length || values?.difference?.length) {
      delete values.difference;
    }
    if (!values?.comments) {
      delete values.comments;
    }
    await axios
      .put(`${ENV.API_URL}/hdp-gateway/booking`, values, {
        headers: { Authorization: sessionStorage.getItem("token") },
      })
      .then((res) => {
        toast.success("Updated successfully");
        handleClose();
        // close();
        getTableData();
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(
          err.response?.data.errors[0].message
            ? err.response?.data.errors[0].message
            : "An error occurred"
        );
      });
  };

  const getTypes = async () => {
    await axios
      .get(`${ENV.API_URL}/hdp-gateway/booking/types`, {
        headers: {
          Authorization: sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        setTypes(res.data.data);
      })
      .catch((err) => {
        toast.error("An error occurred");
      });
  };

  const getRooms = async () => {
    await axios
      .get(`${ENV.API_URL}/hdp-gateway/room`, {
        headers: {
          Authorization: sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        setRooms(res.data.data);
      })
      .catch((err) => {
        toast.error("An error occurred");
      });
  };

  useEffect(() => { }, []);

  useEffect(() => {
    getTypes();
    getRooms();
    // eslint-disable-next-line
  }, []);
  const onCalculateTotal = (e, values) => {
    values.room_id = e.target.value;
    rooms.map((value, index) => {
      if (value.id === values.room_id) {
        const start = new Date(values.start_date);
        const end = new Date(values.end_date);
        const difference_time = end.getTime() - start.getTime();
        let days = Math.round(difference_time / (1000 * 3600 * 24));
        if (days === 0) values.amount = value.price;
        else values.amount = days * value.price;
      }
    });
    setPrice(values?.amount)

  };

  const onStartDate = (e, values) => {
    values.start_date = e.target.value;
    rooms.map((value, index) => {
      if (value.id === values.room_id) {
        const start = new Date(values.start_date);
        const end = new Date(values.end_date);
        const difference_time = end.getTime() - start.getTime();
        let days = Math.round(difference_time / (1000 * 3600 * 24));
        if (days === 0) values.amount = value.price;
        else values.amount = days * value.price;
      }
    });
    setPrice(values?.amount)

  };
  const onEndDate = (e, values) => {
    values.end_date=e.target.value
    rooms.map((value, index) => {
      if (value.id === values.room_id) {
        const start = new Date(values.start_date);
        const end = new Date(values.end_date);
        const difference_time = end.getTime() - start.getTime();
        let days = Math.round(difference_time / (1000 * 3600 * 24));
        if (days === 0) values.amount = value.price;
        else values.amount = days * value.price;
      }
    });
    setPrice(values?.amount)
  };
  return (
    <div>
      <Typography onClick={handleOpen} title="Click">
        {t("Update")}
      </Typography>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{ backdrop: { timeout: 500 } }}
      >

        <Fade in={open}>
          {Data && (
            <Box sx={style} className="modal_res">
              <Typography
                id="transition-modal-title"
                variant="h6"
                component="h2"
                className="marginBottom"
              >
                {t("Update Booking info")}
              </Typography>
              {wait ? (
                <Loading />
              ) : (
                <Formik
                  initialValues={Data}
                  validationSchema={validate}
                  onSubmit={async (values) => {
                    onUpdate(values);
                  }}
                >
                  <Form autoComplete="off" className="mt-4">
                    <>
                      <Grid container spacing={4}>
                        <Grid item xs={12}>
                          <Field
                            size="small"
                            fullWidth
                            name="first_name"
                            label={t("First name")}
                            autoComplete="first_name"
                            component={TextField}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Field
                            size="small"
                            fullWidth
                            name="last_name"
                            label={t("Last name")}
                            autoComplete="last_name"
                            component={TextField}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          {rooms.length ? (
                            <Field
                              fullWidth
                              select
                              onBlur={(e) => onCalculateTotal(e, row)}
                              name="room_id"
                              size="small"
                              component={TextField}
                              label={t("Select Room")}
                            >
                              {rooms.map((item, index) => {
                                return (
                                  <MenuItem value={item.id} key={index}>
                                    {`${item.category_name},  ${item.number}-xona,  ${item.number_of_people}-kishilik`}
                                  </MenuItem>
                                );
                              })}
                            </Field>
                          ) : (
                            <Alert severity="info">
                              You don't have any rooms yet. Please add some
                              rooms and came here.
                            </Alert>
                          )}
                        </Grid>
                        <Grid item xs={12}>
                          <label>{t("Start date")}</label>
                          <Field
                            fullWidth
                            size="small"
                            type="date"
                            onInput={(e) => onStartDate(e, row)}
                            name="start_date"
                            component={TextField}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <label>{t("End date")}</label>
                          <Field
                            size="small"
                            fullWidth
                            onInput={(e) => onEndDate(e, row)}
                            name="end_date"
                            component={TextField}
                            type="date"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Field
                            fullWidth
                            select
                            name="type"
                            size="small"
                            component={TextField}
                            label={t("Select type")}
                          >
                            {types.map((item, index) => {
                              return (
                                <MenuItem value={item.id} key={index}>
                                  {item.name}
                                </MenuItem>
                              );
                            })}
                          </Field>
                        </Grid>
                        <Grid item xs={12}>
                          <Field
                            size="small"
                            fullWidth
                            label={t("Amount")}
                            name="amount"
                            component={TextField}
                            autoComplete="amount"
                            value={price}
                            type="number"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Field
                            size="small"
                            fullWidth
                            label={t("Comment")}
                            name="comments"
                            component={TextField}
                            autoComplete="comment"
                          />
                        </Grid>
                      </Grid>
                    </>
                    <div className="right_left">
                      <Button
                        onClick={handleClose}
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        color="error"
                      >
                        {t("Close")}
                      </Button>
                      <>
                        {loading ? (
                          <LoadingButton
                            loading
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            color="success"
                          >
                            {t("Submit")}
                          </LoadingButton>
                        ) : (
                          <Button
                            type="submit"
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            color="success"
                          >
                            {t("Submit")}
                          </Button>
                        )}
                      </>
                      {loading && <div className="overlay" />}
                    </div>
                  </Form>

                </Formik>
              )}
            </Box>
          )}
        </Fade>
      </Modal>
    </div>
  );
}

export default React.memo(TransitionsModal);
