import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";
import axios from "axios";
import Alert from "@mui/material/Alert";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import Loading from "../Loading.jsx";
import AddUser from "./AddUser.jsx";
import Review from "./Review.jsx";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import ENV from "../../environment";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) return -1;
  if (b[orderBy] > a[orderBy]) return 1;
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}


function EnhancedTableHead(props) {
  const { t } = useTranslation()
  const headCells = [
    { id: "login", numeric: true, label: "ID" },
    { id: "first_name", numeric: true, label: t("First name") },
    { id: "last_name", numeric: true, label: t("Last name") },
    { id: "middle_name", numeric: true, label: t("Middle name") },
    { id: "email", numeric: true, label: t("Email") },
    { id: "phone_number", numeric: true, label: t("Phone number") },
    { id: "created_date", numeric: true, label: t("Created date") },
    { id: "actions", numeric: true, label: t("More") },
  ];
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTable(props) {
  const { handleOpenMainModal, isPartner } = props;
  const { t } = useTranslation()
  const [id, setId] = React.useState(null);
  const [idx, setIdx] = React.useState("");
  const [data, setData] = React.useState([]);
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [anchor, setAnchor] = React.useState(null);

  const getData = async () => {
    await axios
      .get(`${ENV.API_URL}/hdp-gateway/user`, {
        headers: {
          Authorization: sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        setLoading(false);
        setData(res.data.data);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.response?.data?.errors[0]?.message || t("An error occurred"))
        setError(true);
      });
  };
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const openMenu = (event, elem) => {
    setIdx(elem);
    setAnchor(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchor(null);
  };

  React.useEffect(() => {
    getData();
  }, []);
  return (
    <div className="container mt-3">
      <h3>{t("Employees")}</h3>
      <AddUser
        handleOpenMainModal={handleOpenMainModal}
        isPartner={isPartner}
        getData={getData} />
      {loading ? (
        <div
          style={{
            marginTop: "100px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Loading />
        </div>
      ) : (
        <Box sx={{ width: "100%", marginTop: "20px" }}>
          {!error && data.length === 0 && (
            <Alert variant="outlined" severity="warning">
              {t("No Data yet")}
            </Alert>
          )}
          {error && (
            <Alert variant="outlined" severity="error">
              {t("An error occurred")}
            </Alert>
          )}
          {data.length >= 1 && (
            <Paper sx={{ width: "100%", mb: 2 }}>
              <TableContainer>
                <Table
                  sx={{ minWidth: 750, px: 2 }}
                  aria-labelledby="tableTitle"
                  size="medium"
                >
                  <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    rowCount={data.length}
                  />
                  <TableBody>
                    {stableSort(data, getComparator(order, orderBy))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        return (
                          <TableRow hover tabIndex={-1} key={row.id}>
                            <TableCell align="left">{row.login}</TableCell>
                            <TableCell align="left">{row.first_name}</TableCell>
                            <TableCell align="left">{row.last_name}</TableCell>
                            <TableCell align="left">{row.middle_name}</TableCell>
                            <TableCell align="left">{row.email}</TableCell>
                            <TableCell align="left">{row.phone}</TableCell>
                            <TableCell align="left">{row?.created_date?.slice(0, 10)}</TableCell>
                            <TableCell align="left">
                              <Box sx={{ flexGrow: 0 }}>
                                <Tooltip title="Actions">
                                  <IconButton
                                    style={{
                                      borderRadius: "50%",
                                      padding: "2px",
                                    }}
                                    onClick={(e, elem = row) =>
                                      openMenu(e, elem)
                                    }
                                    sx={{ p: 0 }}
                                  >
                                    <MoreVertIcon />
                                  </IconButton>
                                </Tooltip>
                                <Menu
                                  onClick={() => setId(row.id)}
                                  sx={{ mt: "23px" }}
                                  id="menu-appbar"
                                  anchorOrigin={{
                                    vertical: "top",
                                    horizontal: "left",
                                  }}
                                  transformOrigin={{
                                    vertical: "top",
                                    horizontal: "left",
                                  }}
                                  anchorEl={anchor}
                                  open={Boolean(anchor)}
                                  onClose={closeMenu}
                                >
                                  <MenuItem>
                                    <Review
                                      data={idx}
                                      close={closeMenu}
                                    />
                                  </MenuItem>
                                </Menu>
                              </Box>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 100]}
                component="div"
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          )}
        </Box>
      )}
    </div>
  );
}

export default React.memo(EnhancedTable);
