import React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import PersonIcon from "@mui/icons-material/Person";
import Avatar from "@mui/material/Avatar";
import { useTranslation } from "react-i18next";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function TransitionsModal({ data, close }) {
  const {t} = useTranslation()
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    close();
  };

  return (
    <div>
      <Typography onClick={handleOpen} title="Click">
        {t("Review")}
      </Typography>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{ backdrop: { timeout: 500 } }}
      >
        <Fade in={open}>
          <Box sx={style} className="modal_res">
            <Typography
              id="transition-modal-title"
              variant="h6"
              component="h2"
              className="marginBottom"
            >
              {t("Roles of employee")}
            </Typography>
            <List
              sx={{ width: "100%", maxWidth: "100%", display:"flex", flexDirection:"column"}}
            >
              {data?.roles?.map((value) => {
                return (
                  <ListItem className="space-between">
                      <ListItemAvatar>
                        <Avatar>
                          {value.role === "manager" ? (
                            <ManageAccountsIcon />
                          ) : null}
                          {value.role === "supervisor" ? (
                            <SupervisorAccountIcon />
                            ) : null}
                          {value.role === "front desk" ? <PersonIcon /> : null}
                          {value.role === "admin" ? (
                            <AdminPanelSettingsIcon />
                          ) : null}
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary={value.role} />
                  </ListItem>
                );
              })}
            </List>
            <Button
              onClick={handleClose}
              fullWidth
              variant="contained"
              color="error"
              size="small"
              sx={{ mt: 3, mb: 2 }}
            >
             {t("Close")}
            </Button>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

export default React.memo(TransitionsModal);