import React, { useState, useEffect } from "react";
import styles from "../modal-styles.module.css";
import styles2 from "./booking-styles.module.css";
import { Button, Input } from "antd";
import { useTranslation } from "react-i18next";
import { Field, FieldArray, Form, Formik } from "formik";
import { AntDatePicker, AntInput, AntSelect } from "../../../hooks/createAntDFields";
import * as Yup from "yup";
import { toast } from "react-toastify";
import axios from "axios";
import { TextField } from "formik-material-ui";
import ENV from "../../../environment";
import { dateDDMMYYYY, dateLocalISO } from "../../../hooks/dateFormatter";
import dayjs from "dayjs";

const guestInitialValue = {
    document_attachment: "",
    first_name: "",
    last_name: "",
    middle_name: "",
    email: "",
    phone: "",
    document_type: "",
    document: "",
};

const document_types = [
    { value: 1, label: "Passport" },
    { value: 2, label: "ID" },
    { value: 3, label: "Driver’s license" },
];

export default function ShaxmatkaTransfer({ setShow, show, transferdata, getData, categories }) {
    const { t } = useTranslation();
    const [showtrue, setShowTrue] = useState(false);

    useEffect(() => setShowTrue(show), [show]);

    const [sideStyle, setSideStyle] = useState({});
    const resize = () => {
        if (window?.innerWidth && window?.innerWidth < 576) setSideStyle({ height: "100%", width: "100%", top: "0" });
        else setSideStyle({ height: "calc(100% - 64px)", width: "480px", top: "64px" });
    };
    useEffect(resize, []);
    window.onresize = resize;

    const close = (e) => {
        // if (e.currentTarget === e.target) hide();
    };
    const [updatableForm, setUpdatableForm] = useState(true);
    const hide = (resetForm) => {
        setUpdatableForm(true);
        setShowTrue(false);
        if (resetForm) resetForm();
        setTimeout(() => setShow(false), 300);
    };

    const [cats, setCats] = useState(categories.map((e) => ({ value: e.id, label: e.name })));
    const [rooms, setRooms] = useState([]);
    const [catWaiting, setCatWaiting] = useState(false);

    useEffect(() => {
        setCats(categories.map((e) => ({ value: e.id, label: e.name })));
    }, [categories]);

    useEffect(() => {
        if (transferdata.category) {
            setCatWaiting(true);
            axios
                .get(`${ENV.API_URL}/hdp-gateway/room/cat/${transferdata?.category}`, { headers: { Authorization: sessionStorage.getItem("token") } })
                .then((res) => {
                    setRooms(res.data.data.map((e) => ({ value: e.id, label: e.number, price: e.price })));
                    setCatWaiting(false);
                })
                .catch((err) => {
                    toast.error(err?.response?.data?.errors[0]?.message || t("An error occurred"));
                    setCatWaiting(false);
                });
        }
    }, [transferdata]);

    const changeCategoryHandler = async (cat, values, setValues) => {
        if (cat) {
            setCatWaiting(true);
            if (setValues) {
                values.room_category = cat;
                values.room_id = "";
            }
            await axios
                .get(`${ENV.API_URL}/hdp-gateway/room/cat/${cat}`, { headers: { Authorization: sessionStorage.getItem("token") } })
                .then((res) => {
                    if (setValues) setValues(values);
                    setRooms(res.data.data.map((e) => ({ value: e.id, label: e.number, price: e.price })));
                    // setRooms(res.data.data.map((e) => ({ value: e.id, label: e.number, price: e.price })));
                    // setValues({ ...values, room_category: cat, room_id: undefined });
                })
                .catch((err) => toast.error(err?.response?.data?.errors[0]?.message || t("An error occurred")));
            setCatWaiting(false);
        }
    };

    const checkSchema = Yup.object({
        room_id: Yup.string().required(t("Field is required")),
        start_date: Yup.string().required(t("Field is required")),
        end_date: Yup.string().required(t("Field is required")),
        room_category: Yup.string().required(t("Field is required")),
        credit: Yup.string().required(t("Field is required")),
        discount: Yup.number().min(0, "min: 0%").max(100, "max: 100%"),
        comments: Yup.string().optional(t("Field is required")),
        guests: Yup.array(
            Yup.object({
                first_name: Yup.string().required(t("Field is required")),
                last_name: Yup.string().required(t("Field is required")),
                middle_name: Yup.string().optional(),
                email: Yup.string().email().optional(t("Field is required")),
                phone: Yup.string().optional(t("Field is required")),
                document_type: Yup.string().optional(t("Field is required")),
                document: Yup.string()
                    .optional(t("Field is required"))
                    .matches(/^[A-Z0-9_ ]{1,100}$/, { message: t("value invalid") }),
                document_attachment: Yup.string().optional(t("Field is required")),
            })
        ),
    });

    const calcDebit = (values) => {
        let room = rooms.find((e) => e.value === values.room_id);
        return room?.price * (1 - (values.discount || 0) / 100) * dateDiffInDays(values.start_date?.$d, values.end_date?.$d) || 0;
    };

    const qy = document.querySelector.bind(document);
    const submit = async (event, { resetForm }, setSubmitting) => {
        if (!event.start_date.$d || new Date(dateLocalISO(event.start_date?.$d)) > new Date(dateLocalISO(new Date())))
            return toast.error("Start date is invalid");
        if (!event.end_date.$d || new Date(dateLocalISO(event.start_date?.$d)) >= new Date(dateLocalISO(event.end_date?.$d)))
            return toast.error("End date is invalid");
        if (!event?.comments?.length) delete event.comments;
        if (!event?.middle_name?.length) delete event.middle_name;
        if (!event?.email?.length) delete event.email;
        if (!event?.document_type?.length) delete event.document_type;
        if (!event?.document?.length) delete event.document;
        if (!event?.phone?.length) delete event.phone;
        if (!event?.document_attachment?.length) delete event.document_attachment;

        const body = new FormData();
        if (transferdata.booking_id) body.append("id", transferdata.booking_id);

        body.append("room_id", event.room_id);
        body.append("debit", event.debit);
        body.append("credit", event.credit);
        body.append("comments", event.comments);
        body.append("guestsLength", event.guests.length);
        event.guests.forEach((el, i) => {
            body.append(`guests[${i}].id`, el.id);
        });

        await axios
            .put(`${ENV.API_URL}/hdp-gateway/check-in/transfer`, body, {
                headers: { Authorization: sessionStorage.getItem("token"), "Content-Type": "application/json" },

            })
            .then(() => {
                setShow(false);
                getData();
                toast.success(t("Saved successfully"));
                resetForm();
            })
            .catch((err) => {
                console.log(err);
                toast.error(err?.response?.data?.errors[0]?.message || t("An error occurred"));
            });
        setSubmitting(false);
    };
    return (
        <div className={showtrue ? styles.fade : undefined} onClick={close}>
            <Formik
                initialValues={{
                    room_category: transferdata.room?.category.id,
                    room_id: transferdata.room?.id,
                    type: transferdata.bookingType,
                    start_date: dayjs(dateDDMMYYYY(new Date()), "DD.MM.YYYY"),
                    end_date: dayjs(dateDDMMYYYY(transferdata.end_date || new Date()), "DD.MM.YYYY"),
                    credit: 0,
                    debit: 0,
                    comments: transferdata.bookingComment || "",
                    guests: transferdata.guests?.map((guest) => ({
                        id: guest.guest_id,
                        document_attachment: undefined,
                        middle_name: guest.middle_name || "",
                        first_name: guest.first_name,
                        last_name: guest.last_name,
                        email: guest.email || "",
                        phone: guest.phone || "",
                        document_type: guest.document_type || "",
                        document: guest.document || "",
                    })),
                }}
                validationSchema={checkSchema}
                enableReinitialize={updatableForm}
                onSubmit={submit}
            >
                {({ values, isSubmitting, submitCount, resetForm, setValues }) => (
                    <div className={styles.modal} style={{ ...sideStyle, right: showtrue ? "0" : "-480px" }}>
                        <div className={styles.title}>
                            <span className={styles.close} onClick={() => hide(resetForm)}>
                                &#x2715;
                            </span>
                        </div>
                        <h5 style={{ padding: "10px" }}>{t("Transfer other room")}</h5>
                        <div></div>
                        <Form autoComplete="off">
                            <div className={`${styles2.bookingContainer} bookingContainer`}>
                                <div className={styles2.bookingKey}>{t("Category")}</div>
                                <Field
                                    component={AntSelect}
                                    name="room_category"
                                    submitCount={submitCount}
                                    options={cats}
                                    onSelect={(e) => changeCategoryHandler(e, values, setValues)}
                                />
                                <div className={styles2.bookingKey}>{t("Room")}</div>
                                <Field component={AntSelect} name="room_id" submitCount={submitCount} options={rooms} disabled={catWaiting} />
                                <div className={styles2.bookingKey}>{t("Move date")}</div>
                                <Field component={AntDatePicker} name="start_date" submitCount={submitCount} disabled format={"DD.MM.YYYY"} allowClear={false} />
                                <div className={styles2.bookingKey}>{t("End date")}</div>
                                <Field component={AntDatePicker} name="end_date" disabled submitCount={submitCount} format={"DD.MM.YYYY"} allowClear={false} />
                                <hr />
                                <hr />
                                <h4>{t("Additional payment")}</h4><div></div>
                                <div className="small"> {t("Difference amount: ")}</div>
                                <div className="small">
                                    {values.end_date?.$d &&
                                        Math.round(
                                            (rooms.find((room) => room.id = values.room_id)?.price - transferdata?.room?.price) *
                                            dateDiffInDays(values.start_date?.$d, values.end_date?.$d) || 0
                                        )}
                                </div>
                                <div className="small"> {t("Difference amount for one day: ")}</div>
                                <div className="small">
                                    {values.end_date?.$d &&
                                        Math.round(
                                            (rooms.find((room) => room.id = values.room_id)?.price - transferdata?.room?.price) || 0
                                        )}
                                </div>
                                <div className={styles2.bookingKey}>{t("Amount")}</div>

                                <div className="small">
                                    <Field component={AntInput} name="debit" submitCount={submitCount} />
                                </div>
                                <div className={styles2.bookingKey}>{t("Credit")}</div>
                                <Field component={AntInput} name="credit" submitCount={submitCount} />
                                <div className={styles2.bookingKey}>{t("Comment")}</div>
                                <Field component={AntInput} name="comments" submitCount={submitCount} />
                                <hr />
                                <hr />

                                <FieldArray name="guests">
                                    {({ push, remove }) => (
                                        <React.Fragment>
                                            {values.guests?.map((guest, index) => (
                                                <React.Fragment key={index}>
                                                    <div className={styles2.bookingKey}>{t("First name")}</div>
                                                    <Field component={AntInput} disabled name={`guests[${index}].first_name`} submitCount={submitCount} />
                                                    <div className={styles2.bookingKey}>{t("Last name")}</div>
                                                    <Field component={AntInput} disabled name={`guests[${index}].last_name`} submitCount={submitCount} />
                                                </React.Fragment>
                                            ))}
                                        </React.Fragment>
                                    )}
                                </FieldArray>
                            </div>
                            <Button style={{ float: "right", marginRight: "10px" }} type="primary" htmlType="submit">
                                {isSubmitting ? t("Transfering") : t("Transfer")}
                            </Button>
                        </Form>
                    </div>
                )}
            </Formik>
        </div>
    );
}

function dateDiffInDays(a, b) {
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;
    // Discard the time and time-zone information.
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}
