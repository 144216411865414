import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import Loading from "../Loading";
import styles from "./styles.module.css";
import { useTranslation } from "react-i18next";
import ENV from "../../environment";
import { dateDDMMYYYY, dateTime } from "../../hooks/dateFormatter";
import avatar from "../../assets/guestAvatar.png";
import TransactionsTable from "./TransactionsTable";
import OpenDocument from "./Passport";
import { Alert } from "antd";

const GuestInfo = ({ id, data }) => {
    const [info, setInfo] = useState({});
    const [loading, setLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const { t } = useTranslation();

    const getData = async () => {
        setLoading(true);
        setIsError(false);
        await axios
            .get(`${ENV.API_URL}/hdp-gateway/check-in/${id}/details`, {
                headers: { authorization: sessionStorage.getItem("token") },
            })
            .then((res) => {
                setInfo(res.data.data);
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
                toast.error(err?.response?.data?.errors[0]?.message || t("An error occurred"));
                setIsError(true);
                setLoading(false);
            });
    };

    useEffect(() => {
        if (!data || typeof data !== "object" || Object.keys(data).length === 0) getData();
        else setInfo(data);
    }, []);
    console.log(info)
    return (
        <div>
            {loading ? (
                <Loading />
            ) : isError ? (
                <Alert message={t("Something went wrong")} type="error" />
            ) : (
                <div className={styles.container}>
                    <div className={styles.guests}>
                        {info.guests?.map((guest) => (
                            <div className={styles.guest} key={guest.id}>
                                <img src={avatar} alt="avatar" className={styles.guestImage} />
                                <div className={styles.guestName}>
                                    {guest.first_name} {guest.last_name} {guest.middle_name}
                                </div>
                                {(guest.phone || guest.email) && (
                                    <div className={styles.guestContact}>
                                        {guest.email} {guest.phone && guest.email && "/"} {guest.phone}
                                    </div>
                                )}
                                <div className={styles.guestDocument}>
                                    <OpenDocument disabled={!guest.document_attachment} guest={guest} />
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className={styles.bookingContainer}>
                        <div className={styles.booking}>
                            <div>{t("Booked by")}</div>
                            <div>{info.booked || "-"}</div>
                            <hr />
                            <div>{t("Booked date")}</div>
                            <div>{dateTime(info.booked_date) || "-"}</div>
                            <hr />
                            <div>{t("Checked by")}</div>
                            <div>{info.checked || "-"}</div>
                            <hr />
                            <div>{t("Checked date")}</div>
                            <div>{dateTime(info.checked_date) || "-"}</div>
                            {info.checkout_date && (
                                <React.Fragment>
                                    <hr />
                                    <div>{t("Check out by")}</div>
                                    <div>{info.checkout || "-"}</div>
                                    <hr />
                                    <div>{t("Check out date")}</div>
                                    <div>{dateTime(info.checkout_date) || "-"}</div>
                                </React.Fragment>
                            )}
                        </div>
                        {info.books?.map((book) => (
                            <div key={book.room_number} className={styles.booking}>
                                <div>{t("Room")}</div>
                                <div>{book.room_number}</div>
                                <hr />

                                <div>{t("Room category")}</div>
                                <div>
                                    <div className={styles.roomCategory}>{book.room_category_name}</div>
                                </div>
                                <hr />
                                <div>{t("Room price for one night")}</div>
                                <div>{(book.room_price)}</div>
                                <hr />
                                {book.moved && (
                                    <>
                                        <div>{t("Moved by")}</div>
                                        <div>{book.moved}</div>
                                        <hr />
                                    </>
                                )}

                                <div>{t("Start date")}</div>
                                <div>{dateDDMMYYYY(book.start_date)}</div>
                                <hr />

                                <div>{t("End date")}</div>
                                <div>{dateDDMMYYYY(book.end_date)}</div>
                            </div>
                        ))}


                        <div className={styles.transactions}>
                            <TransactionsTable data={info.transactions} />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default GuestInfo;
