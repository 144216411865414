import { useEffect, useState } from "react";
import {
  Grid,
  Box,
  Button
} from "@mui/material";
import Card from "./card";
import Loading from "../Loading";
import { useTranslation } from "react-i18next";

function DataTable({ roomsProp, isLoading, handleClick }) {
  const { t } = useTranslation();

  const [rooms, setRooms] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [roomsPerPage] = useState(12);

  useEffect(() => {
    setRooms(roomsProp)
  }, [roomsProp]);

  useEffect(() => {
    setLoading(isLoading)
  }, [isLoading]);

  const indexOfLastRoom = currentPage * roomsPerPage;
  const indexOfFirstRoom = indexOfLastRoom - roomsPerPage;

  const roomsToShow = rooms.slice(indexOfFirstRoom, indexOfLastRoom);

  const transmissionHandleClick = (item, value) => {
    let trueItem = item.target.tagName === "svg" ? item.target.parentElement.parentElement : item.target.parentElement;
    handleClick(trueItem, value);
  }

  return (
    <div className="container">
      <div>
        {loading ? (
          <Loading />
        ) : (
          <Box>
            <Grid container spacing={2}>
              {roomsToShow.length > 0 ? (
                roomsToShow.map((room) => (
                  <Grid item key={room.id} xs={12} sm={6} md={4} lg={3}>
                    <Card data={room} transmissionedHandleClick={transmissionHandleClick} />
                  </Grid>
                ))
              ) : (
                <p style={{ marginTop: '20px' }}>{t("No rooms found")}</p>
              )}
            </Grid>
          </Box>
        )}

        {(rooms.length > roomsPerPage && !loading) && (
          <div className="pagination mt-3" style={{ textAlign: "center" }}>
            {[...Array(Math.ceil(rooms.length / roomsPerPage)).keys()].map(
              (number) => (
                <Button
                  key={number + 1}
                  onClick={() => setCurrentPage(number + 1)}
                  variant={currentPage === number + 1 ? "contained" : "outlined"}
                  color="primary"
                  sx={{
                    marginRight: "5px",
                    minWidth: "40px"
                  }}
                >
                  {number + 1}
                </Button>
              )
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default DataTable;
