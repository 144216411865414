import React from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";
import axios from "axios";
import Alert from "@mui/material/Alert";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { toast } from "react-toastify";
import { IconButton, Menu, MenuItem, Tooltip, Typography } from "@mui/material";
import Loading from "../Loading.jsx";
import AddCategories from "./AddCategories.jsx";
import UpdateCategories from "./UpdateCategories.jsx";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import ENV from "../../environment.js";

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) return -1;
    if (b[orderBy] > a[orderBy]) return 1;
    return 0;
}

function getComparator(order, orderBy) {
    return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

let headCells = [
    { id: "name", numeric: true, label: t("Name") },
    { id: "description", numeric: true, label: t("Description") },
    { id: "price", numeric: true, label: t("Price") },
    { id: "number_of_people", numeric: true, label: t("Number of people") },
    { id: "is_shared", numeric: true, label: t("Share") },
    { id: "actions", numeric: true, label: t("Action") },
];

function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell key={headCell.id} align="left" sortDirection={orderBy === headCell.id ? order : false}>
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

function EnhancedTable() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [id, setId] = React.useState(null);
    const [deleteLoading, setDeleteLoading] = React.useState(false);
    const [idx, setIdx] = React.useState("");
    const [data, setData] = React.useState([]);
    const [error, setError] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState("calories");
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [anchor, setAnchor] = React.useState(null);
    
    const getData = async () => {
        await axios
            .get(`${ENV.API_URL}/hdp-gateway/room/category`, {
                headers: {
                    Authorization: sessionStorage.getItem("token"),
                },
            })
            .then((res) => {
                setLoading(false);
                setData(res.data.data);
            })
            .catch((err) => {
                setLoading(false);
                toast.error(err?.response?.data?.errors[0]?.message || t("An error occurred"));
                setError(true);
            });
    };
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const openMenu = (event, elem) => {
        setIdx(elem);
        setAnchor(event.currentTarget);
    };

    const closeMenu = () => {
        setAnchor(null);
    };

    const onDelete = async (e) => {
        setDeleteLoading(true);
        await axios
            .delete(`${ENV.API_URL}/hdp-gateway/room/category/${e}`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: sessionStorage.getItem("token"),
                },
            })
            .then((res) => {
                setDeleteLoading(false);
                toast.success(t("Deleted successfully"));
                closeMenu();
                getData();
            })
            .catch((err) => {
                setDeleteLoading(false);
                toast.error(err?.response?.data?.errors[0]?.message || t("An error occurred"));
            });
    };
    const onPicture = (id) => {
        navigate(`/categories/${id}`);
    };
    const onAmenities = (id) => {
        navigate(`/categories/${id}/amenities`);
    };

    React.useEffect(() => {
        getData();
    }, []);
    return (
        <div className="container mt-3">
            {deleteLoading && <div className="overlay"></div>}
            <h3>{t("Categories")}</h3>
            <AddCategories getData={getData} />
            {loading ? (
                <div
                    style={{
                        marginTop: "100px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Loading />
                </div>
            ) : (
                <Box sx={{ width: "100%", marginTop: "20px" }}>
                    {!error && data.length === 0 && (
                        <Alert variant="outlined" severity="warning">
                            {t("No Data yet")}
                        </Alert>
                    )}
                    {error && (
                        <Alert variant="outlined" severity="error">
                            {t("An error occurred")}
                        </Alert>
                    )}
                    {data.length >= 1 && (
                        <Paper sx={{ width: "100%", mb: 2 }}>
                            <TableContainer>
                                <Table sx={{ minWidth: 750, px: 2 }} aria-labelledby="tableTitle" size="medium">
                                    <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} rowCount={data.length} />
                                    <TableBody>
                                        {stableSort(data, getComparator(order, orderBy))
                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((row, index) => {
                                                return (
                                                    <TableRow hover tabIndex={-1} key={row.id}>
                                                        <TableCell align="left">{row.name}</TableCell>
                                                        <TableCell align="left">{row.description}</TableCell>
                                                        <TableCell align="left">{row.price}</TableCell>
                                                        <TableCell align="left">{row.number_of_people}</TableCell>
                                                    <TableCell align="left">{row.is_shared ? t("Yes") : t("No")}</TableCell>
                                                        <TableCell align="left">
                                                            <Box sx={{ flexGrow: 0 }}>
                                                                <Tooltip title="Actions">
                                                                    <IconButton
                                                                        style={{
                                                                            borderRadius: "50%",
                                                                            padding: "2px",
                                                                        }}
                                                                        onClick={(e, elem = row) => openMenu(e, elem)}
                                                                        sx={{ p: 0 }}
                                                                    >
                                                                        <MoreVertIcon />
                                                                    </IconButton>
                                                                </Tooltip>
                                                                <Menu
                                                                    onClick={() => setId(row.id)}
                                                                    sx={{ mt: "23px" }}
                                                                    id="menu-appbar"
                                                                    anchorOrigin={{
                                                                        vertical: "top",
                                                                        horizontal: "left",
                                                                    }}
                                                                    transformOrigin={{
                                                                        vertical: "top",
                                                                        horizontal: "left",
                                                                    }}
                                                                    anchorEl={anchor}
                                                                    open={Boolean(anchor)}
                                                                    onClose={closeMenu}
                                                                >
                                                                    <MenuItem onClick={(e) => onPicture(idx.id)}>
                                                                        <Typography>{t("Pictures")}</Typography>
                                                                    </MenuItem>
                                                                    <MenuItem onClick={(e) => onAmenities(idx.id)}>
                                                                        <Typography>{t("Amenities")}</Typography>
                                                                    </MenuItem>
                                                                    <MenuItem onClick={(e) => onDelete(idx.id)}>
                                                                        <Typography>{t("Delete")}</Typography>
                                                                    </MenuItem>
                                                                    <MenuItem>
                                                                        <UpdateCategories id={idx.id} getTableData={getData} close={closeMenu} />
                                                                    </MenuItem>
                                                                </Menu>
                                                            </Box>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25, 100]}
                                component="div"
                                count={data.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Paper>
                    )}
                </Box>
            )}
        </div>
    );
}

export default React.memo(EnhancedTable);
