import { green, grey, yellow, blue, lightGreen } from "@mui/material/colors";
import { IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Loading from "../Loading";
import GuestList from "./guestsList";
import { useTranslation } from "react-i18next";

// Icons
import MoreVertIcon from "@mui/icons-material/MoreVert";

const StyledDataGrid = styled(DataGrid)(() => ({
  "& .super-app-theme--busy": {
    backgroundColor: green[600],
    "&:hover": {
      backgroundColor: green[600],
    },
    "&.Mui-selected": {
      backgroundColor: green[800],
      color: "#fff",
      fontWeight: "bold",
      "&:hover": {
        backgroundColor: green[800],
      },
    },
  },
  "& .super-app-theme--dirty": {
    backgroundColor: grey[600],
    "&:hover": {
      backgroundColor: grey[600],
    },
    "&.Mui-selected": {
      backgroundColor: grey[800],
      color: "#fff",
      fontWeight: "bold",
      "&:hover": {
        backgroundColor: grey[800],
      },
    },
  },
  "& .super-app-theme--cleaning.in.progress": {
    backgroundColor: yellow[600],
    "&:hover": {
      backgroundColor: yellow[600],
    },
    "&.Mui-selected": {
      backgroundColor: yellow[800],
      color: "#fff",
      fontWeight: "bold",
      "&:hover": {
        backgroundColor: yellow[800],
      },
    },
  },
  "& .super-app-theme--cleaning.complete": {
    backgroundColor: lightGreen[600],
    "&:hover": {
      backgroundColor: lightGreen[600],
    },
    "&.Mui-selected": {
      backgroundColor: lightGreen[800],
      color: "#fff",
      fontWeight: "bold",
      "&:hover": {
        backgroundColor: lightGreen[800],
      },
    },
  },
  "& .super-app-theme--in.maintains": {
    backgroundColor: blue[600],
    "&:hover": {
      backgroundColor: blue[600],
    },
    "&.Mui-selected": {
      backgroundColor: blue[800],
      color: "#fff",
      fontWeight: "bold",
      "&:hover": {
        backgroundColor: blue[800],
      },
    },
  },
  '& .MuiTablePagination-selectLabel': {
    marginBottom: 0
  },
  '& .MuiTablePagination-displayedRows': {
    marginBottom: 0
  },
  '& .MuiTablePagination-select': {
    paddingBottom: 0
  },
}));

function DataTable({ roomsProp, isLoading, handleClick, fetchData }) {
  const { t } = useTranslation();

  useEffect(() => {
    setRooms(roomsProp);
  }, [roomsProp]);

  useEffect(() => {
    setLoading(isLoading)
  }, [isLoading]);

  const [guests, setGuests] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [selectedRows, setSelectedRows] = useState([])
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const columns = [
    { field: "number", headerName: t("Room number"), width: 130, align: "center" },
    {
      field: "category_name",
      headerName: t("Category"),
      width: 260,
      align: "left",
    },
    {
      field: "number_of_people",
      headerName: t("Number of people"),
      width: 150,
      align: "center",
    },
    {
      field: "price",
      headerName: t("Price"),
      width: 260,
      align: "center",
    },
    {
      field: "status_name",
      headerName: t("Status"),
      width: 260,
      align: "center",
    },
    {
      field: "action",
      headerName: t("Actions"),
      width: 221,
      align: "right",
      renderCell: (params) => {
        return (
          <>
            <IconButton
              aria-label="more"
              aria-controls="long-menu"
              aria-haspopup="true"
              onClick={(e) => {
                handleClick(e?.currentTarget, [params.row?.id].map((id) =>
                  rooms.find((row) => row.id === id)
                )[0]);
                setAnchorEl(e.currentTarget);
                onRowsSelectionHandler([params.row?.id])
              }}
            >
              <MoreVertIcon style={{ color: "#fff" }} />
            </IconButton></>
        );
      },
    },
  ];

  const onRowsSelectionHandler = (id) => {
    setGuests([]);
    setTimeout(() => {
      const selectedRowsData = id.map((id) =>
        rooms.find((row) => row.id === id)
      );

      setSelectedRows(selectedRowsData);
      setGuests(selectedRowsData[0]?.guests);
    }, 500);
  };

  return (
    <div className="container">
      <div>
        {loading ? (
          <Loading />
        ) : (
          <Box>
            <StyledDataGrid
              {...rooms}
              initialState={{
                ...rooms?.initialState,
                pagination: { paginationModel: { pageSize: 5 } },
              }}
              pageSizeOptions={[5, 10, 25, 100]}
              autoHeight
              rows={rooms}
              columns={columns}
              pageSize={10}
              rowsPerPageOptions={[10]}
              getRowClassName={(params) =>
                `super-app-theme--${params.row.status_name}`
              }
              onRowSelectionModelChange={(id) => onRowsSelectionHandler(id)}
            />
          </Box>
        )}
      </div>
      {guests.length ? (
        <div className="grid_border pb-3 mt-3">
          <GuestList data={guests} />
        </div>
      ) : null}
    </div>
  );
}

export default DataTable;