import React, { useState, useEffect } from "react";
import styles from "../modal-styles.module.css";
import corestyles from "../styles.module.css";
import { useTranslation } from "react-i18next";
import RoomCharge from "../RoomCharge/RoomCharge";

export default function ShaxmatkaGuests({ setShow, show, guests }) {
  const { t } = useTranslation();
  const [showtrue, setShowTrue] = useState(false);
  useEffect(() => setShowTrue(show), [show]);

  const [sideStyle, setSideStyle] = useState({});
  const resize = () => {
    if (window?.innerWidth && window?.innerWidth < 576)
      setSideStyle({ height: "100%", width: "100%", top: "0" });
    else
      setSideStyle({
        height: "calc(100% - 64px)",
        width: "480px",
        top: "64px",
      });
  };
  useEffect(resize, []);
  window.onresize = resize;

  const close = (e) => {
    if (e.currentTarget === e.target) hide();
  };

  const hide = () => {
    setShowTrue(false);
    setTimeout(() => setShow(false), 300);
  };

  return (
    <div className={showtrue ? styles.fade : undefined} onClick={close}>
      <div
        className={styles.modal}
        style={{ ...sideStyle, right: showtrue ? "0" : "-480px" }}
      >
        <div className={styles.title}>
          <span className={styles.close} onClick={hide}>
            &#x2715;
          </span>
        </div>
        {guests.map((booking) => (
          <div className={corestyles.bookingContainer} key={booking.guest_id}>
            <div>
              {t("Start date")}: {booking[0].start_date.slice(0, 10)}
            </div>
            <div>
              {t("End date")}: {booking[0].end_date.slice(0, 10)}
            </div>
            <div className="mt-1 mb-1">
            {t("Guests")}:
            </div>
            {booking.map((guest, index) => (
              <div className={corestyles.bookingGuest}>
                <RoomCharge index={index} guest_id={guest.guest_id} guest={guest} showtrue={showtrue}/>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}
