import React from "react";
import Button from "@mui/material/Button";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import LoadingButton from "@mui/lab/LoadingButton";
import Grid from "@mui/material/Grid";
import * as Yup from "yup";
import { toast } from "react-toastify";
import axios from "axios";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useTranslation } from "react-i18next";
import ENV from "../../environment";

const ChangePassword = () => {
  const { t } = useTranslation();
  const [visibleIcon, setVisibleIcon] = React.useState(true);
  const [visibleIcon2, setVisibleIcon2] = React.useState(true);
  const [expanded, setExpanded] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const validate = Yup.object({
    old_password: Yup.string().required(`${t("old password is required")}`),
    new_password: Yup.string().required(`${t("new password is required")}`),
  });

  const emptyValue = {
    old_password: "",
    new_password: "",
  };

  const handleSubmit = async (event, { resetForm }) => {
    setLoading(true);
    const body = {
      old_password: event.old_password,
      new_password: event.new_password,
    };
    await axios
      .post(`${ENV.API_URL}/hdp-gateway/user/change-password`, body, {
        headers: {
          Authorization: sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        toast.success(t("Updated successfully"));
        resetForm({ values: "" });
        setExpanded(false);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.response?.data?.errors[0]?.message || t("An error occurred"))
      });
  };

  return (
    <div>
      <Accordion expanded={expanded}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          onClick={() => setExpanded(!expanded)}
        >
          <Button variant="contained" color="success">
            {t("Update password")}
          </Button>
        </AccordionSummary>
        <AccordionDetails>
          <Formik
            initialValues={emptyValue}
            validationSchema={validate}
            onSubmit={async (values, { resetForm }) => {
              handleSubmit(values, { resetForm });
              return new Promise((res) => setTimeout(res, 500));
            }}
          >
            {({ values, errors, resetForm }) => (
              <Form autoComplete="off">
                <Grid container spacing={2}>
                  <Grid item xs={12} className="pass_wrapper">
                    <Field
                      size="small"
                      fullWidth
                      name="old_password"
                      label={t("Old password")}
                      component={TextField}
                      type={visibleIcon ? "password" : "text"}
                    />
                    {visibleIcon ? (
                      <VisibilityOffIcon
                        onClick={() => setVisibleIcon((prev) => !prev)}
                      />
                    ) : (
                      <RemoveRedEyeIcon
                        onClick={() => setVisibleIcon((prev) => !prev)}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} className="pass_wrapper">
                    <Field
                      size="small"
                      fullWidth
                      name="new_password"
                      label={t("New password")}
                      component={TextField}
                      type={visibleIcon2 ? "password" : "text"}
                    />
                    {visibleIcon2 ? (
                      <VisibilityOffIcon
                        onClick={() => setVisibleIcon2((prev) => !prev)}
                      />
                    ) : (
                      <RemoveRedEyeIcon
                        onClick={() => setVisibleIcon2((prev) => !prev)}
                      />
                    )}
                  </Grid>
                </Grid>
                <div className="right_left">
                  <Button
                    onClick={() => setExpanded(!expanded)}
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    color="error"
                  >
                    {t("Close")}
                  </Button>
                  <>
                    {loading ? (
                      <LoadingButton
                        loading
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        color="success"
                      >
                        {t("Save")}
                      </LoadingButton>
                    ) : (
                      <Button
                        type="submit"
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        color="success"
                      >
                         {t("Save")}
                      </Button>
                    )}
                  </>
                  {loading && <div className="overlay" />}
                </div>
              </Form>
            )}
          </Formik>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default ChangePassword;
