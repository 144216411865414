import * as React from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import ENV from "../../environment";

const defaultTheme = createTheme();

export default function StepOne() {
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();
  const handleSubmit = async (event) => {
    setLoading(true);
    await axios
      .post(
        `${ENV.API_URL}/hdp-gateway/hotel/login/self`,
        {
          login: event.login,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        sessionStorage.setItem("name", res?.data?.data?.name);
        sessionStorage.setItem("logo", res?.data?.data?.logo);
        sessionStorage.setItem("license", res?.data?.data?.license);
        sessionStorage.setItem("partner", res?.data?.data?.partner);
        sessionStorage.setItem("login", event?.login);
        setLoading(false);
        navigate("/");
      })
      .catch((err) => {
        setLoading(false);
        toast.error("Login filled");
      });
  };

  const validate = Yup.object({
    login: Yup.string().required("Id is required"),
  });
  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid
        container
        component="main"
        sx={{ height: "90vh", display: "flex", justifyContent: "center" }}
      >
        <Grid item xs={12} sm={8} md={5}>
          <Box
            sx={{
              marginTop: 20,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div className="card">
              <div className="card-body">
                {loading && <div className="overlay" />}
                <div className="d-flex justify-content-center">
                <Typography component="h1" variant="h5" className="Sign_title">
                  {t("Log in step one")}
                </Typography>
                </div>
                <Formik
                  initialValues={{ login: "" }}
                  validationSchema={validate}
                  onSubmit={async (values) => {
                    handleSubmit(values);
                    return new Promise((res) => setTimeout(res, 500));
                  }}
                >
                  {({ values, errors }) => (
                    <Form autoComplete="off">
                      <Grid container spacing={2}>
                        <Grid item xs={12} className="login_input">
                          <Field
                            fullWidth
                            size="small"
                            name="login"
                            label="ID"
                            autoComplete="id"
                            component={TextField}
                          />
                        </Grid>
                      </Grid>
                      {loading ? (
                        <Button
                          className="login_input"
                          loading
                          type="submit"
                          fullWidth
                          disabled={loading}
                          variant="contained"
                        >
                          {t("Log in")}
                        </Button>
                      ) : (
                        <Button
                          className="login_input"
                          type="submit"
                          fullWidth
                          disabled={loading}
                          variant="contained"
                        >
                          {t("Log in")}
                        </Button>
                      )}
                      <Grid container>
                        <div className="d-flex justify-content-end mt-3">
                          <Link to="/register">
                            {t("Haven't added your hotel yet? Add a hotel")}
                          </Link>
                        </div>
                      </Grid>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
