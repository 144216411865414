/* eslint-disable no-useless-escape */
import { object, string } from "yup";

const allRegEx = /^[A-Za-z]{1,1}[a-zA-Z0-9- ',.]{0,}[a-z0-9]{1,}$/;
const zip_code_regex = /^[0-9a-z-]{4,10}$/i;
const passwordRegex = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;
export const personalSchema = object({
  first_name: string()
    .matches(allRegEx, { message: "Invalid first_name" })
    .required("first_name is required"),
  last_name: string()
    .matches(allRegEx, { message: "Invalid last_name" })
    .required("last_name is required"),
  middle_name: string().matches(allRegEx, { message: "Invalid middle_name" }),
  password: string()
    .matches(passwordRegex, { message: "invalid password" })
    .required("password is required"),
});

export const addressSchema = object({
  country: string()
    .required("Country is required"),
  region: string()
    .required("Region is required"),
  city: string()
    .required("City is required"),
  address1: string()
    .required("Address1 is required"),
  address2: string()
    .nullable(),
  zip_code: string()
    .matches(zip_code_regex, { message: "invalid zip code" })
    .nullable(),
});
