import React from "react";
import SupervisorAssignment from "../components/Housekeeping/Supervisor-assignment";
import { useNavigate } from "react-router-dom";

const SupervisorAssignmentPagev2 = () => {
  const navigate = useNavigate()

  const buttonStyle = {
    backgroundColor: '#1976d2',
    color: 'white',
    border: 'none',
    borderRadius: '25px',
    padding: '10px 20px',
    margin: '10px 20px',
    fontSize: '16px',
    fontWeight: 'bold',
    display: 'inline-flex',
    alignItems: 'center',
    cursor: 'pointer',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    transition: 'background-color 0.3s ease',
  };

  const hoverStyle = {
    backgroundColor: '#155a9a',
  };

  const arrowStyle = {
    display: 'inline-block',
    width: '12px',
    height: '12px',
    borderTop: '2px solid white',
    borderLeft: '2px solid white',
    transform: 'rotate(-45deg)',
    marginRight: '10px',
    marginTop: '2px',
  };

  const activeStyle = {
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
    transform: 'translateY(2px)',
  };

  return <>
    <button
      className="go-back-button"
      onClick={() => { navigate(-1) }}
      style={buttonStyle}
      onMouseOver={(e) => (e.currentTarget.style.backgroundColor = hoverStyle.backgroundColor)}
      onMouseOut={(e) => (e.currentTarget.style.backgroundColor = buttonStyle.backgroundColor)}
      onMouseDown={(e) => {
        e.currentTarget.style.boxShadow = activeStyle.boxShadow;
        e.currentTarget.style.transform = activeStyle.transform;
      }}
      onMouseUp={(e) => {
        e.currentTarget.style.boxShadow = buttonStyle.boxShadow;
        e.currentTarget.style.transform = 'none';
      }}
    >
      <span className="arrow-left" style={arrowStyle}></span>
      Go Back
    </button>
    <SupervisorAssignment />
  </>;
};

export default SupervisorAssignmentPagev2;



