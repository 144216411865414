import React, { useState, useEffect } from "react";
import styles from "../modal-styles.module.css";
import corestyles from "../styles.module.css";
import { useTranslation } from "react-i18next";
import { Button } from "antd";
import axios from "axios";
import { toast } from "react-toastify";
import ENV from "../../../environment";
import { useNavigate } from "react-router-dom";

export default function ShaxmatkaCheckOut({ setShow, show, getData, checkOutData }) {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [showtrue, setShowTrue] = useState(false);
    useEffect(() => setShowTrue(show), [show]);

    const [sideStyle, setSideStyle] = useState({});
    const resize = () => {
        if (window?.innerWidth && window?.innerWidth < 576) setSideStyle({ height: "100%", width: "100%", top: "0" });
        else setSideStyle({ height: "calc(100% - 64px)", width: "480px", top: "64px" });
    };
    useEffect(resize, []);
    window.onresize = resize;

    const close = (e) => {
        // if (e.currentTarget === e.target) hide();
    };

    const hide = () => {
        setShowTrue(false);
        setTimeout(() => setShow(false), 300);
    };

    const [selected, setSelected] = useState(null);
    useEffect(() => {
        if (checkOutData.length === 1) {
            setSelected(checkOutData[0][0]?.booking_id);
        }
    }, [checkOutData]);

    const checkOut = async (id) => {
        navigate(`/check-out/${id}`);
    };

    return (
        <div className={showtrue ? styles.fade : undefined} onClick={close}>
            <div className={styles.modal} style={{ ...sideStyle, right: showtrue ? "0" : "-480px" }}>
                <div className={styles.title}>
                    <span className={styles.close} onClick={hide}>
                        &#x2715;
                    </span>
                </div>
                <div>{checkOutData.length > 1 && t("Qaysi biri uchun checkout qilmoqchisiz?")}</div>
                {checkOutData.map((booking) => (
                    <div className={corestyles.bookingContainer} onClick={() => setSelected(booking[0]?.booking_id)}>
                        <div>
                            {t("Start date")}: {booking[0]?.start_date.slice(0, 10)}
                        </div>
                        <div>
                            {t("End date")}: {booking[0]?.end_date.slice(0, 10)}
                        </div>
                        {t("Guests")}:
                        {booking?.map((guest, index) => (
                            <div className={corestyles.bookingGuest}>
                                {index + 1}. {guest.first_name} {guest.last_name}
                            </div>
                        ))}
                        {selected === booking[0]?.booking_id && (
                            <div style={{ height: "30px" }}>
                                <Button style={{ float: "right", marginRight: "10px" }} type="primary" onClick={() => checkOut(selected)}>
                                    {t("Check out")}
                                </Button>
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
}
