import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useTranslation } from "react-i18next";

function capitalizeFirstLetter(text) {
    if (!text) return text; // In case the input is empty or null
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
}
const CardContainer = styled("div")(({ status }) => ({
    padding: "16px",
    borderRadius: "8px",
    backgroundColor: (() => {
        switch (status) {
            case "available":
                return "#e0e0e0";
            case "busy":
                return "#07bc0c";
            case "dirty":
                return "#757575";
            case "cleaning in progress":
                return "#f1c40f";
            case "in maintains":
                return "#0dcaf0";
            case "Booked":
                return '#A020F0'
            default:
                return "#ffffff";
        }
    })(),
    textAlign: "center",
    boxShadow: "0px 2px 8px rgba(0,0,0,0.1)",
    position: "relative",
}));

const Card = ({ data, transmissionedHandleClick }) => {
    const { t } = useTranslation();

    const handleClick = (event, value) => {
        transmissionedHandleClick(event, value);
    };

    return (
        <CardContainer status={data.status_name}>
            <div style={{ display: "flex", justifyContent: "end", alignItems: "end" }}>
                <p style={{ display: 'none' }}>{data.id}</p>
                <IconButton
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    onClick={(e) => handleClick(e, data)}
                >
                    <MoreVertIcon style={{ color: "#fff" }} />
                </IconButton>
            </div>
            <p>{data?.number}</p>
            <p>{data?.category_name}</p>
            <p>{`${t(capitalizeFirstLetter(data?.status_name.toString()))}`}</p>
            <p>{`${t("Number of people")}: ${data?.number_of_people}`}</p>
            <p>{`${t("Price")}: ${data?.price}`}</p>
        </CardContainer>
    );
};

export default Card;
