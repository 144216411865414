import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n/index";
import "./index.css";
import App from "./App";
import axios from "axios";
import ENV from "./environment";

axios.interceptors.request.use(config => {
  if(!config.headers["client-id"]){
    config.headers["client-id"] = ENV.CLIENTID
  }
  if(!config.headers["client-secret"]){
    config.headers["client-secret"] = ENV.CLIENTSECRET
  }
  return config
})

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <I18nextProvider i18n={i18n}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </I18nextProvider>
);
