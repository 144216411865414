import React from "react";
import BigCalendar from "./Calendar";
import "./Calendar.css";

const Index = ({events, setEvents}) => {
  return (
    <div id="big_cal">
      <BigCalendar events={events} setEvents={setEvents}/>
    </div>
  );
};

export default Index;
