import React from "react";
import { Field, Form, Formik, FieldArray } from "formik";
import { TextField } from "formik-material-ui";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";

const App = ({ data }) => {
  const {t} = useTranslation()
  return (
    <div className="container">
      <h5 className="mb-3 mt-3">{t("Guests of selected room")}</h5>
      <Formik initialValues={data}>
        {({ values, errors, resetForm }) => (
          <Form autoComplete="off">
            <Grid container spacing={2}>
              <FieldArray name="educations">
                {({ push, remove }) => (
                  <React.Fragment>
                    {data?.map((_, index) => (
                      <Grid container item key={index} spacing={2}>
                        <Grid item xs={12} md={6}>
                        <label>{t("First name")}</label>
                          <Field
                            size="small"
                            fullWidth
                            name={`[${index}].first_name`}
                            component={TextField}
                            disabled
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                        <label>{t("Last name")}</label>
                          <Field
                            size="small"
                            fullWidth
                            name={`[${index}].last_name`}
                            component={TextField}
                            disabled
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                        <label>{t("Document number")}</label>
                          <Field
                            size="small"
                            fullWidth
                            name={`[${index}].document`}
                            component={TextField}
                            disabled
                          />
                        </Grid>
                      </Grid>
                    ))}
                  </React.Fragment>
                )}
              </FieldArray>
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
  );
};
export default App;
