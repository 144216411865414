import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import LoadingButton from "@mui/lab/LoadingButton";
import Grid from "@mui/material/Grid";
import { toast } from "react-toastify";
import { Alert, MenuItem } from "@mui/material";
import { useTranslation } from "react-i18next";
import axios from "axios";
import * as Yup from "yup";
import ENV from "../../environment";

const AddBooking = ({ getData }) => {
  const {t} = useTranslation()
  const [rooms, setRooms] = React.useState([]);
  const [types, setTypes] = React.useState([]);
  const [categories, setCategories] = React.useState([]);
  const [expanded, setExpanded] = React.useState(false);
  const [loading, setLoading] = React.useState(false); 

  const validate = Yup.object({
    first_name: Yup.string().required(t("Field is required")),
    last_name: Yup.string().required(t("Field is required")),
    room_category: Yup.number().required(t("Field is required")),
    room_id: Yup.number().required(t("Field is required")),
    start_date: Yup.string().required(t("Field is required")),
    end_date: Yup.string().required(t("Field is required")),
    type: Yup.number().required(t("Field is required")),
    amount: Yup.number().required(t("Field is required")).test(
      'Is positive?', 
      (t("Enter only positive number")), 
      (value) => value >= 0
    ),
    credit: Yup.number().test(
      'Is positive?', 
      (t("Enter only positive number")), 
      (value) => value >= 0
    ),
    comments: Yup.string(),
  });

  const emptyValue = {
    first_name: "",
    last_name: "",
    room_category: "",
    room_id: "",
    start_date: "",
    end_date: "",
    type: "",
    amount: "",
    credit: "",
    comments: "",
  };

  const handleSubmit = async (event, { resetForm }) => {
    setLoading(true);
    if (!event?.comments) delete event.comments;
    if (!event?.credit) delete event.credit;

    const body = {
      first_name: event.first_name,
      last_name: event.last_name,
      room_category: event.room_category,
      room_id: event.room_id,
      start_date: event.start_date,
      end_date: event.end_date,
      type: event.type,
      debit: event.amount,
      credit: event?.credit,
      comments: event?.comments,
    };
    await axios.post(`${ENV.API_URL}/hdp-gateway/booking`, body, {
        headers: { Authorization: sessionStorage.getItem("token") },
      })
      .then((res) => {
        toast.success(t("Saved successfully"));
        setRooms([])
        resetForm({ values: "" });
        setExpanded(false);
        setLoading(false);
        getData();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.response?.data?.errors[0]?.message || t("An error occurred"));
      });
  };

  const getTypes = async () => {
    await axios.get(`${ENV.API_URL}/hdp-gateway/booking/types`, {
        headers: { Authorization: sessionStorage.getItem("token") },
      }).then((res) => { setTypes(res.data.data) })
      .catch((err) => { toast.error(err?.response?.data?.errors[0]?.message || t("An error occurred")) });
  };

  const getCategories = async () => {
    await axios.get(`${ENV.API_URL}/hdp-gateway/room/category`, {
        headers: { Authorization: sessionStorage.getItem("token") },
      }).then((res) => { setCategories(res.data.data) })
      .catch((err) => { toast.error(err?.response?.data?.errors[0]?.message || t("An error occurred")) });
  };

  const onChange = async (e,values) => {
      values.room_category = e.target.value
    if(e){
      values.room_id = ""
      await axios.get(`${ENV.API_URL}/hdp-gateway/room/cat/${e.target.value}`, {
        headers: { Authorization: sessionStorage.getItem("token") },
      }).then((res) => setRooms(res.data.data))
      .catch((err) =>  toast.error(err?.response?.data?.errors[0]?.message || t("An error occurred")));
    }
  }

  const onCalculateTotal = (e,values) => {
    values.room_id = e.target.value
    rooms.map((value,index)=>{
     if(value.id === values.room_id){
        const start = new Date(values.start_date);
        const end = new Date(values.end_date);
        const difference_time  = end.getTime() - start.getTime();
        let days = Math.round(difference_time / (1000 * 3600 * 24));
        if(days===0) values.amount = value.price
        else values.amount = days * value.price
     }
   });
 }

  const onStartDate = (e,values) => {
    values.start_date = e.target.value
    rooms.map((value,index)=>{
      if(value.id === values.room_id){
        const start = new Date(values.start_date);
        const end = new Date(values.end_date);
        const difference_time  = end.getTime() - start.getTime();
        let days = Math.round(difference_time / (1000 * 3600 * 24));
        if(days===0) values.amount = value.price;
        else values.amount = days * value.price;
      }
    });
 }
 const onEndDate = (e,values) => {
   values.end_date = e.target.value
  rooms.map((value,index)=>{
   if(value.id === values.room_id){
      const start = new Date(values.start_date);
      const end = new Date(values.end_date);
      const difference_time  = end.getTime() - start.getTime();
      let days = Math.round(difference_time / (1000 * 3600 * 24));
      if(days===0) values.amount = value.price
      else values.amount = days * value.price
   }
 });
}

  useEffect(() => {
    getTypes();
    getCategories();
  }, []);

  return (
    <div>
      <Accordion expanded={expanded}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" onClick={() => setExpanded(!expanded)}>
          <Button variant="contained" color="success">{t("Add new booking")}</Button>
        </AccordionSummary>
        <AccordionDetails>
          <Formik initialValues={emptyValue} validationSchema={validate} onSubmit={async (values, { resetForm }) => handleSubmit(values, { resetForm })}>
            {({ values }) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Field size="small" fullWidth name="first_name" label={t("First name")} component={TextField}/>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field size="small" fullWidth name="last_name" label={t("Last name")} component={TextField}/>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <label>{t("Start date")}</label>
                    <Field OnInput={(e) => onStartDate(e, values)} size="small" fullWidth name="start_date" component={TextField} type="date"/>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <label>{t("End date")}</label>
                    <Field OnInput={(e) => onEndDate(e, values)} size="small" fullWidth name="end_date" component={TextField} type="date"/>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {categories.length ? (
                      <Field fullWidth select name="room_category" size="small" component={TextField} label={t("Select Category")} onChange={(e)=>onChange(e,values)}>
                        {categories.map((item, index) => <MenuItem value={item.id} key={index}>{item.name}</MenuItem> )}
                      </Field>
                    ) : (
                      <Alert severity="info">
                        You don't have any categories yet. Please add some
                        categories and came here.
                      </Alert>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field onBlur={(e)=>onCalculateTotal(e, values)} disabled={!rooms.length ? true : false}
                      fullWidth select name="room_id"size="small"component={TextField}label={t("Select Room")}>
                      {
                        rooms?.map((item, index) => 
                          <MenuItem value={item.id} key={index}>{`${item.number}-xona,  ${item.number_of_people}-kishilik`}</MenuItem>)
                      }
                    </Field>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    { types.length ? (
                      <Field fullWidth select name="type" size="small" component={TextField} label={t("Select type")}>
                        { types.map((item, index) => <MenuItem value={item.id} key={index}>{item.name}</MenuItem> )}
                      </Field> ) : ( <Alert severity="info">You don't have any types.</Alert> )
                    }
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field size="small" fullWidth label={t("Amount")} onWheel={(e) => e.target.blur()} name="amount" component={TextField} type="number"/>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field size="small" fullWidth label={t("Credit")} onWheel={(e) => e.target.blur()} name="credit" component={TextField} type="number"/>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field size="small" fullWidth label={t("Comment")} name="comments" component={TextField}/>
                  </Grid>
                </Grid>
                <div className="right_left">
                  <Button onClick={() => setExpanded(!expanded)} variant="contained" sx={{ mt: 3, mb: 2 }} color="error">{t("Close")}</Button>
                  <>
                    {loading ? (
                      <LoadingButton loading variant="contained" sx={{ mt: 3, mb: 2 }} color="success">{t("Submit")}</LoadingButton>
                    ) : (
                      <Button type="submit" variant="contained" sx={{ mt: 3, mb: 2 }} color="success">{t("Submit")}</Button>
                    )}
                  </>
                  {loading && <div className="overlay" />}
                </div>
              </Form>
            )}
          </Formik>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default AddBooking;
