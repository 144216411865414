import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import Button from "@mui/material/Button";
import { CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ENV from "../../environment";
import { useTranslation } from "react-i18next";

const Upload = ({ token }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [toggle, setToggle] = useState(false);
  const [errorTitle, setErrorTitle] = useState("");
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [skip, setSkip] = useState(false);

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!file) {
      toast.warning("Choose file!");
      return;
    } else if (file) {
      setLoading(true);
      const formData = new FormData();
      formData.append("file", file);
      await axios
        .post(`${ENV.API_URL}/hdp-gateway/fth/logo`, formData, {
          headers: {
            authorization: "Bearer " + token,
            "Content-Type": "multipart/form-data",
          },
          setToggle,
        })
        .then((res) => {
          setLoading(true);
          setFile(null);
          axios
            .patch(
              `${ENV.API_URL}/hdp-gateway/fth/check`,
              {},
              {
                headers: { Authorization: token },
              }
            )
            .then(() => {
              toast.success("Successfully activated, Please login");
              navigate("/login");
              setLoading(true);
            })
            .catch(() => {});
          setToggle(!toggle);
          setLoading(true);
        })
        .catch((err) => {
          setFile(null);
          toast.error(
            err?.response?.data?.errors[0]?.message || t("An error occurred")
          );
          setErrorTitle(
            err?.response?.data?.errors[0]?.message || t("An error occurred")
          );
          setLoading(false);
        });
    } else {
      return toast.error(t("An error occurred"));
    }
  };

  const onSkip = async () => {
    setSkip(true);
    await axios
      .patch(
        `${ENV.API_URL}/hdp-gateway/fth/check`,
        {},
        {
          headers: { Authorization: token },
        }
      )
      .then(() => {
        toast.success("Successfully activated, Please login");
        navigate("/login");
        setSkip(true);
      })
      .catch(() => {
        setSkip(false);
        return toast.error("An error occurred");
      });
  };

  return (
    <div className="d-flex justify-content-center flex-column container py-3 check_scroll">
      <div className="py-2">
        <div className="card p-3">
          <div className="card-header">
            <h5 className="text-center">Upload logo</h5>
            {loading && <div className="overlay" />}
            {skip && <div className="overlay" />}
          </div>
          <div>
            <div>
              <form>
                <div className="right_left mt-3 btn-res">
                  <div>
                    <>
                      <Button variant="outlined" component="label">
                        Select logo
                        <input
                          onChange={(e) => setFile(e.target.files[0])}
                          hidden
                          accept="image/*"
                          type="file"
                          className="form-control"
                        />
                      </Button>
                      <span>{file?.name}</span>
                      <h6 style={{ color: "red" }}>{errorTitle}</h6>
                    </>
                  </div>
                  <Button
                    size="medium"
                    component="label"
                    type="submit"
                    variant="outlined"
                    color="success"
                    disabled={file ? false : true}
                    startIcon={loading && <CircularProgress size="0.9rem" />}
                    onClick={onSubmit}
                  >
                    Submit
                  </Button>
                </div>
                <Button
                  fullWidth
                  disabled={file ? true : false}
                  style={{ float: "right", marginTop: "10px" }}
                  size="medium"
                  component="label"
                  type="submit"
                  variant="outlined"
                  color="success"
                  startIcon={skip && <CircularProgress size="0.9rem" />}
                  onClick={onSkip}
                >
                  Skip Logo
                </Button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Upload;
